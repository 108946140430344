import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';
import {MailModel} from "../_models/mail.model";

@Injectable({
    providedIn: 'root'
})
export class MailService {

    private url: any = environment.url_api_backend + '/admin/mail/';

    constructor(private http: HttpClient) {
    }

    sendMailToEmployes(data: MailModel) {
        return this.http.post(this.url + 'send-mail-to-employes', data);
    }

    sendMailToSociete(data: MailModel) {
        return this.http.post(this.url + 'send-mail-to-societe', data);
    }

}
