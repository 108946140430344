import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {StatutModel} from '../../_models/statut.model';
import {StatutService} from '../../_services/statut.service';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy, OnChanges {

    @Output() selectedStatut: EventEmitter<any> = new EventEmitter();
    @Input() status: StatutModel[] = [];
    @Input() statut_id: any;
    @Input() statut: StatutModel = new StatutModel();
    public validationForm: FormGroup;
    public subscription: Subscription = new Subscription();
    public submitted = false;

    constructor(
        private formBuilder: FormBuilder,
        private statutService: StatutService,
    ) {
    }

    ngOnInit() {

        //this.statut.id = this.statut_id;
        this.validationForm = this.formBuilder.group({
            statut_id: ['', Validators.required],
        });

        /**
         * get list status
         */
        if (!this.status || this.status.length == 0) {
            this.subscription = this.statutService.getAll().subscribe((res: any) => {
                this.status = res.data;
            }, error1 => {

            }, () => {

            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    setStatut() {
        this.selectedStatut.emit(this.statut);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.statut_id) {
            this.statut.id = this.statut_id;
        }
    }

}
