import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {TokenService} from './token.service';
import {Observable} from 'rxjs';

@Injectable()
export class BeforeLoginService implements CanActivate {

    constructor(private Token: TokenService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        if (!this.Token.loggedIn()) {
            return true;
        } else {
            this.router.navigate(['/auth']);
        }
    }
}
