import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'randomNumber'
})
export class RandomNumberPipe implements PipeTransform {

    public colors: any = ['yellow', 'green', 'blue', 'black', 'red'];
    transform(value: any, ...args: any[]): any {
        return this.colors[Math.floor(Math.random() * value)];
    }

}
