export class ParametreModel {
    id?: any = null;
    nom?: any = null;
    adresse?: any = null;
    email?: any = null;
    site_web?: any = null;
    tel?: any = null;
    latitude?: any = null;
    longitude?: any = null;
    adresse_ip_fixe?: any = null;
    pointage_avec_ip_fixe?: any = null;
    pointage_dehors_societe?: any = null;
    perimetre_pointage?: any = null;
    nbr_jours_travail_semaine?: any = null;
    heure_travail_jour?: any = null;
    heure_debut_travail?: any = null;
    heure_sortie_travail?: any = null;
    heure_debut_pause?: any = null;
    heure_fin_pause?: any = null;
    liens?: any = null;
    membres?: any = null;
}
