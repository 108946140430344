import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {EmployeService} from '../../_services/employe.service';
import {GlobalsService} from '../../_services/globals.service';
import {EmployeModel} from '../../_models/employe.model';
import {UserService} from '../../_services/user.service';
import {TypeVoyageModel} from "../../_models/type-voyage.model";
import * as moment from "moment";

@Component({
    selector: 'app-soldes-voyages-restantes',
    templateUrl: './soldes-voyages-restantes.component.html',
    styleUrls: ['./soldes-voyages-restantes.component.scss'],
})
export class SoldesVoyagesRestantesComponent implements OnInit, OnDestroy {

    public solde_restant: any = [];
    public year_now: any = moment().format('Y');
    public month_now: any = moment().format('M');
    @Input() employe: EmployeModel = new EmployeModel();
    @Input() type_voyage: TypeVoyageModel = new TypeVoyageModel();
    @Output() soldes: EventEmitter<any> = new EventEmitter();
    @Output() loading: EventEmitter<any> = new EventEmitter();
    public subscription: Subscription = new Subscription();
    public is_data_load = false;

    constructor(
        public globalsService: GlobalsService,
        private employeService: EmployeService,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        if (!this.employe && this.employe.id) {
            this.employe = this.userService.getEmployeConnecter();
        }
        //console.log(this.employe);
        this.subscription = this.employeService.getAllSoldeVoyageRestant(this.employe.id || this.employe).subscribe((response: any) => {
            this.is_data_load = true;
            this.loading.emit(false);
            //console.log(response.data);
            this.solde_restant = response.data;
            this.soldes.emit(this.solde_restant);
        }, error1 => {
            this.is_data_load = true;
            this.loading.emit(false);
            //console.log(error1);
        }, () => {
            this.loading.emit(false);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
