export const environment = {
    production: true,
    signal_app_id: '7e0ccba1-200a-4fcc-9cc0-b36e84d87475',
    firebase_id: '253356524702',
    session: {
        duree: 1800, // secondes 1800s=30min
        duree_msg: 60 // secondes 60s=1min
    },
    key_map: 'AIzaSyDZIegO-plmur8OuKulGtyTtrTHvOQenb4',
    default_img_formation: 'assets/images/card/default_img_formation_1.jpg',
    default_img_projet: 'assets/images/card/default_img_projet_1.png',
    default_img_flux_activite: 'assets/images/card/default_img_flux_activite_1.jpg',
    default_img_doc_files: 'assets/images/files/all-doc.png',
    default_img_audio_video: 'assets/images/files/audio_video.jpg',

    //url_api_backend: 'http://127.0.0.1:8000/api',
    //url_api_download_file: 'http://127.0.0.1:8000',

    url_api_backend: 'https://grh.backend.benalimahdi.com/api',
    url_api_download_file: 'https://grh.backend.benalimahdi.com',

    default_audio_refresher: 'assets/notification_sound/iOS_12.mp3',
    default_audio_message_notifcation: 'assets/notification_sound/Messanger_Sound.mp3',

    pusher: {
        key: 'f7902e31736ee3820225',
        cluster: 'eu',
    },

    app_version: '1.1.0',
    disable_log: true,
};
