import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class StatutService {

    private url: any = environment.url_api_backend + '/admin/status';
    private url_ressource: any = environment.url_api_backend + '/status';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.url_ressource);
    }

    add(data: any) {
        return this.http.post(this.url_ressource, data);
    }

    put(data: any) {
        return this.http.put(this.url_ressource + '/' + data.id, data);
    }

    remove(id: any) {
        return this.http.delete(this.url_ressource + '/' + id);
    }
}
