import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    private url: any = environment.url_api_backend + '/admin/documents';
    private url_ressource: any = environment.url_api_backend + '/documents';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.url_ressource);
    }

    add(data: any) {
        return this.http.post(this.url_ressource, data);
    }

    put(id, data: any) {
        return this.http.put(this.url_ressource + '/' + id, data);
    }

    editDemande(id, data: any) {
        return this.http.post(this.url + '/update-doc/' + id, data);
    }

    remove(id: any) {
        return this.http.delete(this.url_ressource + '/' + id);
    }

    getAllByEmploye(id: number) {
        return this.http.get(this.url + '/all-documents-by-employe/' + id);
    }

    getAllByEmployeAndTypeDocument(employe_id: number, type_document_id: number) {
        return this.http.get(this.url + '/all-documents-by-employe-and-type-document/' + employe_id + '/' + type_document_id);
    }

    getListeDemandeEnCours(page) {
        return this.http.get(this.url + '/get-liste-demandes-en-cours' + '?page=' + page);
    }

    servirDocument(id, data) {
        return this.http.post(this.url + '/servir-document/' + id, data);
    }
}
