import {EmployeModel} from './employe.model';
import {PosteModel} from './poste.model';

export class EquipeModel {
    id?: any = null;
    parametre_id?: any = null;
    libelle?: any = null;
    employes?: EmployeModel = null;
    poste?: PosteModel = null;
    nbr_employes?: number = null;
}
