import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    myMethod$: Observable<any>;
    private getData = new Subject<any>();

    constructor() {
        this.myMethod$ = this.getData.asObservable();
    }

    setData(data) {
        console.log(data); // I have data! Let's return it so subscribers can use it!
        // we can do stuff with data if we want
        this.getData.next(data);
    }
}
