import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentModel} from '../../../../_models/document.model';
import {AlertController, ModalController, NavParams} from '@ionic/angular';
import {TypeDocumentModel} from '../../../../_models/type-document.model';
import {Subscription} from 'rxjs';
import {StatutModel} from '../../../../_models/statut.model';
import {DocumentService} from '../../../../_services/document.service';
import {UserService} from '../../../../_services/user.service';
import {GlobalsService} from '../../../../_services/globals.service';
import {FileLikeObject, FileUploader} from 'ng2-file-upload';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MainAlertComponent} from '../../../../shared/main-alert/main-alert.component';
import {Router} from "@angular/router";
import * as moment from "moment";

@Component({
    selector: 'app-detail-document',
    templateUrl: './detail-document.component.html',
    styleUrls: ['./detail-document.component.scss'],
})
export class DetailDocumentComponent implements OnInit, OnDestroy {

    // Data passed in by componentProps
    @Input() document: DocumentModel = new DocumentModel();
    @Input() types_documents: TypeDocumentModel[];
    @Input() status: StatutModel[];
    @Input() params: any = [];
    @Input() action: any = 'demande';
    public subscription: Subscription = new Subscription();
    public fileUploader: FileUploader = new FileUploader({});
    public hasBaseDropZoneOver = false;
    public servir_demande_document = false;
    public documentForm: FormGroup;
    public servirForm: FormGroup;
    submitted = false;
    loading = false;
    public files: any = [];

    constructor(
        public navParams: NavParams,
        public modalController: ModalController,
        public router: Router,
        public alertController: AlertController,
        private documentService: DocumentService,
        private userService: UserService,
        public globalsService: GlobalsService,
        private formBuilder: FormBuilder,
    ) {
        if (navParams.get('document')) this.document = navParams.get('document');
        if (navParams.get('document')) this.params = navParams.get('document');
        if (navParams.get('action')) this.action = navParams.get('action');
        console.log(navParams);
    }

    ngOnInit() {
        this.documentForm = this.formBuilder.group({
            type_document: ['', Validators.required],
            mois: ['', Validators.required],
        });

        this.servirForm = this.formBuilder.group({
            statut: ['', Validators.required]
        });
    }


    get f() {
        return this.documentForm.controls;
    }

    dismiss(doc = new DocumentModel(), servir = false) {
        this.modalController.dismiss({
            dismissed: true,
            servir_demande: servir,
            document: doc
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    async demandeDocument(document) {
        console.log();
        this.submitted = true;
        // stop here if form is invalid
        if (this.documentForm.invalid) {
            return;
        }

        // const month: any = (new Date(this.document.mois).getMonth());
        //  this.document.mois = this.globalsService.monthNames[month];
        this.document.statut_id = document.statut_id ? document.statut_id : 1;
        this.document.employe_demande_id = this.userService.getCurrentUser().id;

        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg: 'Voulez-vous mettre a jour votre données personnelle ?'
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                console.log(data.data.result);
                if (data.data && data.data.result) {
                    this.subscription = this.documentService.add(this.document).subscribe((res: any) => {
                        console.log(res);
                        // todo a corriger
                        // this.documents.documents[0] = data.data;
                        this.globalsService.presentToast('Votre demande de document est enregistré avec sucés');
                        this.dismiss(res.data);
                    }, error1 => {
                        this.globalsService.presentToast('Une erreur qui se produit merci de réussi plus tard', 'danger');
                    }, () => {

                    });
                }
            });
        return await modal.present();
    }

    async modifierDocument(document) {
        console.log(document);
        this.submitted = true;
        // stop here if form is invalid
        if (this.documentForm.invalid) {
            return;
        }

        if (this.userService.getPouvoirUserConnecter()) {

        } else {

        }
        if (this.documentForm.invalid) {
            return;
        }

        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg: 'Voulez-vous modifier votre demande de <strong>document</strong> ?'
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                console.log(data.data.result);
                if (data.data && data.data.result) {
                    const formData = new FormData();
                    if (this.files && this.files.length > 0) {
                        this.files.forEach((file, i) => {
                            formData.append('file' + i, file.rawFile, file.name);
                        });
                    }
                    formData.append('document', JSON.stringify(document));
                    this.subscription = this.documentService.editDemande(document.id, formData).subscribe((res: any) => {
                        this.globalsService.presentToast();
                        this.dismiss(res.data);
                    }, error1 => {
                        this.globalsService.presentToast('Une erreur qui se produit merci de réussi plus tard', 'danger');
                    }, () => {

                    });
                }
            });
        return await modal.present();
    }

    fileOverBase(event): void {
        this.hasBaseDropZoneOver = event;
    }

    async servirDocument(document) {
        console.log(document);
        this.submitted = true;
        // stop here if form is invalid
        if (this.documentForm.invalid) {
            return;
        }

        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg: 'Voulez-vous servir cet demande de <strong>document</strong> ?'
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                console.log(data.data.result);
                this.loading = true;
                if (data.data && data.data.result) {
                    const formData = new FormData();
                    if (this.files.length > 0) {
                        this.files.forEach((file, i) => {
                            formData.append('file' + i, file.rawFile, file.name);
                        });
                    }
                    this.document.action = 'servir';
                    this.document.employe_trait_id = this.userService.getIdEmployeConnecter();
                    formData.append('document', JSON.stringify(this.document));
                    this.subscription = this.documentService.servirDocument(this.document.id, formData).subscribe((data: any) => {
                        this.loading = false;
                        this.globalsService.presentToast('Opération effectué evec sucés');
                        this.dismiss(data.data, true);
                    }, error1 => {
                        this.loading = false;
                        this.globalsService.presentToast('Une erreur qui se produit merci de réussi plus tard', 'danger');
                    }, () => {

                    });
                }
            });
        return await modal.present();

    }

    setFiles(files) {
        this.files = files;
    }

    setMonth(event) {
        this.document.mois = moment(event.detail.value).format('M');
    }
}
