import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EmployeService {

    private url: any = environment.url_api_backend + '/admin/employes';
    private url_ressource: any = environment.url_api_backend + '/employes';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.url_ressource);
    }

    add(data: any) {
        console.log(data);
        return this.http.post(this.url_ressource, data);
    }

    put(id, data: any) {
        return this.http.put(this.url_ressource + '/' + id, data);
    }

    remove(id: any) {
        return this.http.delete(this.url_ressource + '/' + id);
    }

    cloturer(employe: any) {
        return this.http.post(this.url + '/cloturer-employe', employe);
    }

    getAllEquipes() {
        return this.http.get(this.url + '/all-equipes');
    }

    getAllEmployesByEquipe(id: number) {
        return this.http.get(this.url + '/all-employes-by-equipe/' + id);
    }

    getAllEquipeWithEmployes() {
        return this.http.get(this.url + '/all-equipes-with-employes');
    }

    getAllDocumetns(id) {
        return this.http.get(this.url + '/all-documetns-by-employe/' + id);
    }

    getSoldeVoyage(employe, type_voyage) {
        return this.http.get(this.url + '/get-solde-restent/employe/' + employe + '/type_voyage/' + type_voyage);
    }


    getAllSoldeVoyageRestant(employe_id) {
        return this.http.get(this.url + '/get-all-solde-restent/employe_id/' + employe_id);
    }

    getMesPointages(employe_id, page) {
        return this.http.get(this.url + '/get-all-pointages-by-employe/' + employe_id + '?page=' + page);
    }

    getMesVoyages(employe_id) {
        return this.http.get(this.url + '/get-all-voyages-by-employe/' + employe_id);
    }

    addCompteUser(user) {
        return this.http.post(this.url + '/update-compte-user', user);
    }

    updateCompteUser(user) {
        return this.http.post(this.url + '/update-compte-user', user);
    }

    getAllParams() {
        return this.http.get(this.url + '/get-all-params');
    }

    saveEmploye(data) {
        return this.http.post(this.url + '/save-employe', data);
    }

    editEmploye(id, data) {
        console.log([id, data]);
        return this.http.post(this.url + '/edit-employe/' + id, data);
    }

    getLastId() {
        return this.http.get(this.url + '/get-last-id-insert');
    }

    getMesAbsences(employe_id) {
        return this.http.get(this.url + '/mes-absences/' + employe_id);
    }

    getAbsencesEquipe() {
        return this.http.get(this.url + '/absences-equipe/');
    }

    getHistoriqueVoyage(employe_id, page) {
        return this.http.get(this.url + '/get-historique-voyage-employe/' + employe_id + '?page=' + page);
    }

    getListeDesDemandeEnCours(page) {
        return this.http.get(this.url + '/get-liste-demandes-en-cours' + '?page=' + page);
    }

    getNombreDemandeEnCours() {
        return this.http.get(this.url + '/get-nombre-demandes-en-cours');
    }

    basculerEmploye(data) {
        return this.http.post(this.url + '/basculer-employe', data);
    }

    allEmployesEnTravail(page) {
        return this.http.get(this.url + '/all-employes-en-travail' + '?page=' + page);
    }

    allEmployesSortant(page) {
        return this.http.get(this.url + '/all-employes-sortant' + '?page=' + page);
    }

    getEmployesBySociete(id) {
        return this.http.get(this.url + '/get-all-employes-by-societe/' + id);
    }

    getInfoSociete(employe_id) {
        return this.http.get(this.url + '/get-info-societe-par-employe/' + employe_id);
    }
}
