export class MessageModel {
    id?: any = null;
    from?: any = null;
    to?: any = null;
    message?: any = '';
    read_at?: any = null;
    path?: any = null;
    equipe_employe_id?: any = null;
    employe_id?: any = null;
    unique_id?: any = null;
    nom?: any = null;
    prenom?: any = null;
    equipe_id?: any = null;
    type_file?: any = null;
    file_name?: any = null;
    created_at?: any = null;
    updated_at?: any = null;
}
