import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {MessageModel} from '../../../_models/message.model';
import {Subscription} from 'rxjs';
import {ChatService} from '../../../_services/chat.service';
import {GlobalsService} from '../../../_services/globals.service';
import {ModalController, NavController} from '@ionic/angular';
import {EquipeService} from '../../../_services/equipe.service';
import {DataService} from '../../../_services/data.service';
import {UserService} from '../../../_services/user.service';
import {MessageComponent} from "./message/message.component";
import {environment} from "../../../../environments/environment.prod";
import * as moment from "moment";

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

    public isItemAvailable = false;
    public isDataLoaded = false;
    public loading = false;
    public tab_users_connected = [];
    public message: MessageModel = new MessageModel();
    public employes: any = [];
    public temp_employes: any = [];
    public minimizedEmployes: any = [];
    public page = 1;
    public subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        public userService: UserService,
        private chatService: ChatService,
        private equipeService: EquipeService,
        public globalsService: GlobalsService,
        private navCtrl: NavController,
        private dataService: DataService,
        private modalController: ModalController,
        private elementRef: ElementRef,
    ) {
    }

    ngOnInit() {
        let self = this;
        localStorage.removeItem('message-user');
        this.getAllEmployes();
        // get list users conneceted to app
        this.globalsService.usersConnected$.subscribe((next: any) => {
            console.log(next);
            this.tab_users_connected = next;
        });

        self.globalsService.lastMessageReceived$.subscribe((next: any) => {
            //console.log(next);
            if (next.to && next.to == self.userService.getIdEmployeConnecter() && next.unique_id) {
                let index = self.employes.findIndex(item => item.unique_id == next.unique_id);
                //self.globalsService.playSoundNewMessageRecieved();
                if (!localStorage.getItem('active_chat') ||
                    (localStorage.getItem('active_chat') && localStorage.getItem('active_chat') != next.unique_id)) {
                    this.employes[index].read_at = null;
                }
                self.employes[index].message = next.message;
            }
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     *
     */
    getAllEmployes() {
        const self = this;
        if (!this.isDataLoaded) this.loading = true;
        this.subscription = this.equipeService.getAllEmployesWorkUnionSortantWithEmploye(this.userService.getIdEmployeConnecter(), this.page).subscribe(
            (res: any) => {
                this.loading = false;
                this.isDataLoaded = true;
                if (res.data && res.data.data && res.data.data.length > 0) {
                    if (res.data.data.length == res.data.per_page) {
                        this.page = res.data.current_page + 1;
                    }
                    res.data.data.forEach(function (v, i) {
                        if (self.employes.findIndex(item => item.employe_id == v.employe_id) < 0) {
                            self.employes.push(v);
                        } else {
                            self.employes[self.employes.findIndex(item => item.employe_id == v.employe_id)] = v;
                        }
                    });
                    this.temp_employes = this.employes;
                }
            }, (error) => {
                this.loading = false;
                this.isDataLoaded = true;
            });
    }

    initializeItems() {
        this.employes = this.temp_employes;
    }

    getItems(event) {
        this.initializeItems();
        // set val to the value of the searchbar
        const val = event.target.value;
        //console.log(val);
        // if the value is an empty string don't filter the items
        if (val && val.trim() != '') {
            this.isItemAvailable = true;
            this.employes = this.employes.filter((item) => {
                return (
                    item.nom.toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1 ||
                    item.prenom.toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1 ||
                    (item.nom.toString().toLowerCase() + ' ' + item.prenom.toString().toLowerCase()).indexOf(val.toString().toLowerCase()) > -1 ||
                    item.libelle.toString().toLowerCase().indexOf(val.toString().toLowerCase()) > -1
                );
            });
        }
    }

    async detailChat(user, is_opened = false) {
        let index = this.employes.findIndex(item => item.unique_id == user.unique_id);
        if (index >= 0) {
            this.employes[index].read_at = moment();
        }
        const modal = await this.modalController.create({
            component: MessageComponent,
            cssClass: 'my-custom-modal-full-size',
            id: user.unique_id,
            backdropDismiss: false,
            componentProps: {
                to_user: user,
                is_opened,
                minimizedEmployes: this.minimizedEmployes,
                tab_users_connected: this.tab_users_connected
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                console.log('***************** chat equipe ********************');
                console.log(data.data);
                let self = this;
                /*if (data.data && data.data.minimize === true) {
                    const index = this.minimizedEmployes.findIndex(element => element.to_user.unique_id === data.data.to_user.unique_id);
                    //console.log(index);
                    if (index < 0) {
                        this.minimizedEmployes.push({
                            to_user: data.data.to_user,
                            message: data.data.message,
                            translate_y: this.globalsService.translateMinimizeCard()
                        });
                        this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
                    } else {
                        this.minimizedEmployes[index] = {
                            to_user: data.data.to_user,
                            message: data.data.message,
                            translate_y: this.globalsService.translateMinimizeCard()
                        };
                        this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
                    }
                } else {
                    const index = this.minimizedEmployes.find(element => element.to_user.unique_id === data.data.to_user.unique_id);
                    this.minimizedEmployes.splice(index, 1);
                    this.globalsService.resetTranslateY();
                    this.minimizedEmployes.forEach(function (v, i) {
                        v.translate_y = self.globalsService.translateMinimizeCard();
                    });
                    this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
                }*/
            });

        return await modal.present();
    }

    trackByFn(index, item) {
        return index;
    }

    getMoreEEmployesPaginateByPage(event) {
        setTimeout(() => {
            this.getAllEmployes();
            //console.log('Async operation has ended');
            event.target.complete();
        }, 500);
    }

    doRefresh(event) {
        this.globalsService.playSoundRefresher();
        this.page = 1;
        this.getAllEmployes();
        setTimeout(() => {
            //console.log('Async operation has ended');
            event.target.complete();
        }, 2000);
    }
}
