import {
    Component,
    ElementRef, HostListener,
    OnDestroy,
    OnInit, Sanitizer,
    ViewChild
} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../_services/user.service";
import {ChatService} from "../../../../_services/chat.service";
import {EquipeService} from "../../../../_services/equipe.service";
import {GlobalsService} from "../../../../_services/globals.service";
import {IonContent, ModalController, NavController} from "@ionic/angular";
import {DataService} from "../../../../_services/data.service";
import {EquipeModel} from "../../../../_models/equipe.model";
import {ViewerModalComponent} from "ngx-ionic-image-viewer";
import {environment} from "../../../../../environments/environment.prod";
import {MainAlertComponent} from "../../../../shared/main-alert/main-alert.component";
import {DomSanitizer} from "@angular/platform-browser";

declare const Pusher: any;

export class MessageEquipe {
    id?: any = null;
    equipe_employe_id?: any = null;
    employe_id?: any = null;
    equipe_id?: any = null;
    nom?: any = null;
    prenom?: any = null;
    message?: any = '';
    path?: any = '';
    unique_id?: any = null;
    type_file?: any = null;
    file_name?: any = null;
    pre_upload?: any = '';
    read_at?: any = null;
    created_at?: any = null;
    updated_at?: any = null;
}

@Component({
    selector: 'app-message-equipe',
    templateUrl: './message-equipe.component.html',
    styleUrls: ['./message-equipe.component.scss'],
})
export class MessageEquipeComponent implements OnInit, OnDestroy {

    toggled = false;
    loading = false;
    public unique_id = '';
    public page = 1;
    public old_page = 1;
    public equipe: EquipeModel = new EquipeModel();
    public equipe_libelle: any = '';
    public app_back_path: any = '';
    public equipe_employe_id: any = '';
    public message: MessageEquipe = new MessageEquipe();
    public temp_message: any = {};
    public messages: MessageEquipe[] = [];
    public subscription: Subscription = new Subscription();
    public pre_upload: any;
    public show_pre_upload = false;
    public files = [];
    public cpt_msg = 0;
    public last_id_msg = null;
    player: YT.Player;
    private id: string = 'qDuKsiwS5xw';
    minimizedEmployes: any = [];

    // @ts-ignore
    @ViewChild(IonContent) content: IonContent

    constructor(
        private router: Router,
        public userService: UserService,
        private chatService: ChatService,
        private equipeService: EquipeService,
        public globalsService: GlobalsService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private dataService: DataService,
        public modalController: ModalController,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit() {
        let self = this;
        self.globalsService.lastMessageReceived$.subscribe((next: any) => {
            if (next && next.message && next.unique_id && next.unique_id.includes(localStorage.getItem('active_chat'))) {
                //self.globalsService.playSoundNewMessageRecieved();
                self.messages.push(next.message);
                self.scrollToBottom();
            }
        })

        this.equipe.id = this.route.snapshot.queryParams.equipe_id;
        this.equipe.libelle = this.route.snapshot.queryParams.equipe_libelle;
        this.app_back_path = this.route.snapshot.queryParams.app_back_path;
        this.equipe_employe_id = this.route.snapshot.queryParams.equipe_employe_id;
        //set unique_id
        this.unique_id = 'chat_equipe_' + this.equipe.id;
        localStorage.setItem('active_chat', this.unique_id);

        this.getAllMessages();

        /**
         * get list minimize card from local storage
         */
        /* setInterval(() => {
             this.getAllMessages();
         }, this.userService.getCurrentUser().DUREE_RELOAD_MSG_ACTIVE || environment.duree_reload_msg);*/

        /**
         * get list minimize card from local storage
         */
        if (localStorage.getItem('minimizedEmployes')) {
            this.minimizedEmployes = JSON.parse(localStorage.getItem('minimizedEmployes'));
        }
    }

    scrollToBottom(scroll_to = 'sroll_to_bottom'): void {
        try {
            if (scroll_to == 'sroll_to_bottom') {
                this.globalsService.display_button = 'bottom';
                this.globalsService.ScrollTo(this.content);
            }
        } catch (err) {
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    /**
     *
     * @param to
     */
    getAllMessages(equipe_id = this.equipe.id, scroll_to = 'scroll_to_bottom') {
        const self = this;
        if (this.cpt_msg == 0) this.loading = true;
        if (this.equipe && this.equipe.id) {
            this.subscription = this.chatService.getMessagesEquipe(this.page, equipe_id).subscribe(
                (res: any) => {
                    //console.log(res);
                    this.loading = false;
                    if (res.data && res.data.data && res.data.data.length > 0) {
                        if (res.data.data.length == res.data.per_page) {
                            this.page++;
                        }
                        //this.page++;
                        res.data.data.forEach(function (v, i) {
                            if (self.messages.findIndex(item => item.id == v.id) < 0) {
                                self.messages.unshift(v);
                            }
                        });
                    }
                    if (this.cpt_msg == 0 && this.messages.length > 0) this.last_id_msg = this.messages[this.messages.length - 1].id;
                    // play sound if new msg recieved
                    if (this.messages.length > 0 && this.messages[this.messages.length - 1].id !== this.last_id_msg) {
                        //this.globalsService.playSoundNewMessageRecieved();
                        this.last_id_msg = this.messages[this.messages.length - 1].id;
                    }
                    this.scrollToBottom(scroll_to);
                }, (error) => {
                    this.loading = false;
                });
        }
    }

    getMoreEMessagePaginateByPage(event) {
        setTimeout(() => {
            this.getAllMessages('scroll_to_top');
            //console.log('Async operation has ended');
            event.target.complete();
        }, 500);
    }

    trackByFn(index, item) {
        return index;
    }

    handleSelection(event) {
        this.message.message += event.char;
    }

    maximizeCard() {
        /**
         * sc-ion-modal-md-h sc-ion-modal-md-s md my-custom-modal-minimize hydrated show-modal
         */
        const element = document.getElementById('chat-equipe-' + this.equipe.id);
        element.className = 'sc-ion-modal-md-h sc-ion-modal-md-s md my-custom-modal-full-size show-modal';
    }

    goBack(minimize = false) {
        //remove active chat
        localStorage.removeItem('active_chat');
        this.navCtrl.navigateBack(this.app_back_path);
    }

    public minimizeCard(minimize = true) {
        let temp_msg = {};
        if (this.messages.length < 1) {
            temp_msg['unique_id'] = this.unique_id;
            temp_msg['libelle'] = this.equipe.libelle;
        } else {
            temp_msg = this.messages[0];
        }
        //alert(this.equipe.id);
        temp_msg['equipe_id'] = this.equipe.id;
        // if (minimize == true) {
        let self = this;
        if (minimize == true) {
            console.log(this.minimizedEmployes);
            const index = this.minimizedEmployes.findIndex(element => element.to_user.unique_id === this.unique_id);
            // change position if screen plain by minimize card
            if (self.globalsService.translate_y >= 455) {
                self.globalsService.decrementTranslateX();
                self.globalsService.resetTranslateY();
            }
            //console.log(this.minimizedEmployes);
            //console.log('unique_id : ' + this.unique_id);
            //console.log('index : ' + index);
            if (index < 0) {
                if(this.minimizedEmployes.length > 0){
                    this.globalsService.translate_y = this.minimizedEmployes[this.minimizedEmployes.length -1].translate_y;
                }
                this.minimizedEmployes.push({
                    to_user: temp_msg,
                    message: this.message,
                    translate_y: this.globalsService.translateMinimizeCard(),
                    translate_x: self.globalsService.translateX(),
                });
                localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
                this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
            } else {
                this.minimizedEmployes[index].to_user = temp_msg;
                localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
                this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
            }
        } else {
            // change position if screen plain by minimize card
            if (self.globalsService.translate_y >= 455) {
                self.globalsService.decrementTranslateX();
                self.globalsService.resetTranslateY();
            }
            const index = this.minimizedEmployes.findIndex(element => element.to_user.unique_id === this.unique_id);
            this.minimizedEmployes.splice(index, 1);
            this.globalsService.resetTranslateY();
            this.minimizedEmployes.forEach(function (v, i) {
                v.translate_y = self.globalsService.translateMinimizeCard();
                v.translate_x = self.globalsService.translateX();
            });
            localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
            this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
        }
        //}
        this.goBack(minimize);
    }

    /**
     *
     * @param message
     */
    sendMessage() {
        let self = this;
        this.temp_message.equipe_employe_id = this.equipe_employe_id;
        this.temp_message.employe_id = this.userService.getIdEmployeConnecter();
        this.temp_message.equipe_id = this.equipe.id;
        this.temp_message.message = this.message.message;
        if (!this.message.message && !this.pre_upload) {
            this.globalsService.presentToast('Le champ message est vide', 'danger');
            return false;
        } else {
            const formData = new FormData();
            formData.append('message', JSON.stringify(this.temp_message));
            formData.append('file', this.files[0]);
            self.loading = true;
            this.chatService.sendMessagesEquipe(formData).subscribe((res: any) => {
                self.loading = false;
                if (res.data && res.data.id) {
                    this.temp_message.id = res.data.id;
                    this.last_id_msg = res.data.id;
                }
                if (res.data && res.data.path) this.temp_message.path = res.data.path;
                if (res.data && res.data.type_file) this.temp_message.type_file = res.data.type_file;
                this.messages.push(this.temp_message);
                this.scrollToBottom();
                //console.log(this.userService.getIdEmployeConnecter());
                //console.log(this.temp_message);
                this.clearFile();
            }, error1 => {
                self.loading = false;
                self.globalsService.presentToast('Message n\'est pas envoyé', 'danger');
                self.message = new MessageEquipe();
            }, () => {
                self.loading = false;
            });
        }
    }

    uploadFile(event) {
        const file = event.target.files[0];
        this.files.push(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            this.message.message = file.name;
            if ([this.globalsService.images_types].includes(this.globalsService.getExtensionFile(file.name))) {
                this.pre_upload = reader.result;
            } else if ([this.globalsService.audio_types].includes(this.globalsService.getExtensionFile(file.name)) && [this.globalsService.video_types].includes(this.globalsService.getExtensionFile(file.name))) {
                this.pre_upload = environment.default_img_audio_video;
            } else {
                this.pre_upload = environment.default_img_doc_files;
            }
        };
    }

    clearFile() {
        this.files = [];
        this.pre_upload = null;
        this.message = new MessageEquipe();
        this.temp_message = {};
    }

    async openViewer(path) {
        //console.log(path);
        const modal = await this.modalController.create({
            component: ViewerModalComponent,
            componentProps: {
                src: path
            },
            cssClass: 'ion-img-viewer',
            keyboardClose: true,
            showBackdrop: true
        });

        return await modal.present();
    }

    savePlayer(player) {
        this.player = player;
        //console.log('player instance', player);
    }

    onStateChange(event) {
        //console.log('player state', event.data);
    }

    async deleteMessage(msg, index) {
        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg: 'Voulez-vous supprimer ce <strong>message</strong> ?'
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                //console.log(data.data.result);
                if (data.data && data.data.result) {
                    this.loading = true;
                    this.subscription = this.chatService.removeMessagesEquipe(msg.id).subscribe((response: any) => {
                        this.loading = false;
                        this.messages = this.globalsService.spliceFromObject(this.messages, msg.id);
                        this.globalsService.presentToast('Message supprimé avec succès');
                    }, error1 => {
                        this.loading = false;
                        this.globalsService.presentToast('Une erreur qui se produit merci de réussi plus tard', 'danger');
                    }, () => {

                    });
                }
            });
        return await modal.present();
    }
}
