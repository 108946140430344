import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-main-ion-refresher',
    templateUrl: './main-ion-refresher.component.html',
    styleUrls: ['./main-ion-refresher.component.scss'],
})
export class MainIonRefresherComponent implements OnInit {

    @Output() doRefresh: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit() {
    }

    refresh(event) {
        this.doRefresh.emit(event);
    }
}
