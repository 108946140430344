import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment.prod';
import {MessageModel} from '../_models/message.model';
import {Socket} from 'ngx-socket-io';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    private url_ressource: any = environment.url_api_backend;
    private url: any = environment.url_api_backend + '/';

    constructor(
        private http: HttpClient,
        // private socket: Socket
    ) {

    }

    /**
     *
     * @param message
     */
    sendMessage(message: any) {
        return this.http.post(this.url + 'send', message);
    }

    /**
     *
     * @param to
     */
    getMessages(page, to) {
        return this.http.get(this.url + 'getDialog/' + to + '?page=' + page);
    }

    /**
     *
     * @param message_id
     */
    setReadAtMessage(from, to) {
        return this.http.post(this.url + 'read-at-message', {from: from, to: to});
    }

    /**
     *
     * @param message_id
     */
    removeMessages(message_id) {
        return this.http.delete(this.url + 'remove-message/' + message_id);
    }


    /* sendMessage(msg: MessageModel) {
         this.socket.emit("message", msg);
     }

     getMessage() {
         //this.getDialog(1,2);
         return this.socket
             .fromEvent("message")
             .pipe(map((data: any) => (data.json())));
     }*/

    getMessagesEquipe(page, equipe_id) {
        return this.http.get(this.url + 'messages-by-equipe/' + equipe_id + '?page=' + page);
    }

    sendMessagesEquipe(message) {
        return this.http.post(this.url + 'send-messages-to-equipe', message);
    }

    setReadAtMessageEquipe(equipe_employe_id) {
        return this.http.post(this.url + 'read-at-message-equipe', equipe_employe_id);
    }

    removeMessagesEquipe(message_id) {
        return this.http.delete(this.url + 'remove-messages-equipe/' + message_id);
    }

    getListMessagesNotReaded() {
        return this.http.get(this.url + 'list-messages-not-readed');
    }

    eventNewMessageSend(unique_id = null) {
        if (unique_id) {
            return this.http.get(this.url + 'verif-new-message-send/' + unique_id);
        }
        return this.http.get(this.url + 'verif-new-message-send');
    }
}
