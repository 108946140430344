import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {ModalController, NavParams} from "@ionic/angular";
import {MailModel} from "../../_models/mail.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MailService} from "../../_services/mail.service";
import {GlobalsService} from "../../_services/globals.service";
import {ParametreModel} from "../../_models/parametre.model";
import {UserService} from "../../_services/user.service";
import {MainAlertComponent} from "../main-alert/main-alert.component";

@Component({
    selector: 'app-send-mail',
    templateUrl: './send-mail.component.html',
    styleUrls: ['./send-mail.component.scss'],
})
export class SendMailComponent implements OnInit, OnDestroy {

    public subscription: Subscription = new Subscription();
    public societe: ParametreModel = new ParametreModel();
    public mail: MailModel = new MailModel();
    public submitted = false;
    public validationForm: FormGroup;

    constructor(
        public router: Router,
        public userService: UserService,
        public globalsService: GlobalsService,
        public modalController: ModalController,
        public navParams: NavParams,
        public fb: FormBuilder,
        public mailService: MailService,
    ) {
    }

    ngOnInit() {

        if (this.navParams.get('societe')) {
            this.societe = this.navParams.get('societe');
        }

        this.mail.name = this.userService.getEmployeConnecter().nom + ' ' + this.userService.getEmployeConnecter().prenom;
        this.mail.from = this.userService.getEmployeConnecter().email;
        this.mail.to = this.societe.email;

        this.validationForm = this.fb.group({
            //from: ['', [Validators.required]],
            to: ['', [Validators.required]],
            sujet: ['', [Validators.required]],
            message: ['', [Validators.required]]
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    dismiss() {
        this.modalController.dismiss({
            dismissed: true
        });
    }

    async envoyer() {
        this.submitted = true;
        if (this.validationForm.invalid) {
            return;
        }


        let msg = '';
        msg = 'Voulez-vous envoyer ce courriel au sociéte  <strong>' + this.societe.nom + '</strong> ?';

        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                console.log(data.data.result);
                if (data.data && data.data.result) {
                    this.globalsService.presentLoading();
                    this.subscription = this.mailService.sendMailToSociete(this.mail).subscribe((response: any) => {
                        console.log(response);
                        this.globalsService.presentDismiss();
                        this.globalsService.presentToast(response.data, 'success');
                        this.dismiss();
                    }, error1 => {
                        console.log(error1);
                        this.globalsService.presentDismiss();
                        this.globalsService.presentToast('Une erreur qui se produit merci de réussi plus tard', 'danger');
                    }, () => {

                    });
                }
            });
        return await modal.present();
    }
}
