import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {now} from 'moment';
var DateDiff = require('date-diff');
declare var require: any;

@Pipe({
    name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        /*const entree = value.entree;
        const pause = value.pause;
        const retour = value.retour;
        const sortie = value.sortie;*/
        let diff;
        if (value && value.length == 4) {
            const entree = value[0];
            const pause = value[1];
            const retour = value[2];
            const sortie = value[3];
            if (entree && !pause && !retour && !sortie) {
                diff = Math.abs((new DateDiff(new Date(entree), (new Date(now())))).hours());
            } else if (entree && pause && !retour && !sortie) {
                diff = Math.abs((new DateDiff(new Date(entree), (new Date(pause)))).hours());
            } else if (entree && pause && retour && !sortie) {
                diff = Math.abs((new DateDiff(new Date(entree), (new Date(pause)))).hours()) + Math.abs((new DateDiff(new Date(retour), (new Date(now())))).hours());
            } else if (entree && pause && retour && sortie) {
                diff = Math.abs((new DateDiff(new Date(entree), (new Date(pause)))).hours()) + Math.abs((new DateDiff(new Date(retour), (new Date(sortie)))).hours());
            } else {
                diff = 0;
            }
            return (parseFloat(diff) - parseFloat('0.0')).toFixed(2);
        } else {
            const date1 = value[0];
            const date2 = value[1];
            if (date2) {
                diff = new DateDiff(new Date(date1), (new Date(date2)));
            } else {
                diff = new DateDiff(new Date(date1), (new Date(now())));
            }
            return (Math.abs(diff.hours())).toFixed(2);
        }
    }

}
