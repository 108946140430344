import {Directive, HostListener} from '@angular/core';
import {ViewerModalComponent} from "ngx-ionic-image-viewer";
import {ModalController} from "@ionic/angular";

@Directive({
  selector: '[appClickHandler]'
})
export class ClickHandlerDirective {

  constructor(
      public modalController: ModalController,
  ) { }

  @HostListener('click', ['$event']) log(e){
    console.log(e)
  }

  async openViewer(path) {
    console.log(path);
    const modal = await this.modalController.create({
      component: ViewerModalComponent,
      componentProps: {
        src: path
      },
      cssClass: 'ion-img-viewer',
      keyboardClose: true,
      showBackdrop: true
    });

    return await modal.present();
  }
}
