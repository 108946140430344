import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {JwtInterceptor} from './_interceptor/jwt-Interceptor.service';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {NgxPermissionsModule} from 'ngx-permissions';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RandomNumberPipe} from './_pipes/random-number.pipe';
import {ServiceWorkerModule} from '@angular/service-worker';
import {ChatModule} from './pages/admin/chat/chat.module';
import {UserIdleModule} from 'angular-user-idle';
import {environment} from '../environments/environment.prod';
import { MinusPipe } from './_pipes/minus.pipe';
registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        AppComponent,
        RandomNumberPipe,
        MinusPipe,
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        SharedModule,
        NgxPermissionsModule.forRoot(),
        BrowserAnimationsModule,
        ChatModule,
        // SocketIoModule.forRoot(config),
        // NgxLaravelEchoModule.forRoot(echoConfig),
        UserIdleModule.forRoot({idle: environment.session.duree, timeout: environment.session.duree_msg, ping: 10}),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'fr'}
    ],
    exports: [
        NgxPermissionsModule,
        BrowserAnimationsModule,
        RandomNumberPipe,
        MinusPipe,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
