import {Component, OnInit} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {GlobalsService} from './_services/globals.service';
import {AlertController, ModalController, Platform} from '@ionic/angular';
import {environment} from "../environments/environment.prod";
import {SplashScreen} from "@ionic-native/splash-screen/ngx";
import {OneSignal} from "@ionic-native/onesignal/ngx";
import {OneSignalDeviceService} from "./_services/one.signal-device.service";
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {OneSignalDeviceModel} from "./_models/one.signal.device.model";
import {UserService} from "./_services/user.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

    public signal_app_id: any = environment.signal_app_id;
    public firebase_id: any = environment.firebase_id;
    oneSignalDevice: OneSignalDeviceModel = new OneSignalDeviceModel();
    constructor(
        private permissionsService: NgxPermissionsService,
        public globalService: GlobalsService,
        private alertCtrl: AlertController,
        private modalController: ModalController,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private oneSignal: OneSignal,
        private oneSignalDeviceSerivce: OneSignalDeviceService,
        private userService: UserService,
    ) {
        if (environment.disable_log == true) {
            console.log = function () {
            }
        }
    }



    ngOnInit(): void {

        let self = this;
        const current_user = this.globalService.getCurrentUser();
        // Set permission role for the current user
        const perm = [];
        if (current_user && current_user.pouvoir) {
            // perm.push(current_user.pouvoir.libelle);
            this.permissionsService.loadPermissions([current_user.pouvoir.libelle]);
        }

        // this.permissionsService.loadPermissions(perm);


        console.log('****************** role ******************');
        console.log(this.permissionsService.getPermissions());

    }
}
