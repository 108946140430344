import {EmployeModel} from './employe.model';
import {PouvoirModel} from './pouvoir.model';

export class UserModel {
    id?: any = null;
    name?: any = null;
    email?: any = null;
    email_verified_at?: any = null;
    password?: any = null;
    employe_id?: any = null;
    pouvoir_id?: any = null;
    remember_token?: any = null;
    photo_profil?: any = null;
    created_at?: any = null;
    updated_at?: any = null;
    employe?: EmployeModel = null;
    pouvoir?: PouvoirModel = null;
}
