import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedRoutingModule} from './shared-routing.module';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {RouteReuseStrategy} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedComponent} from './shared.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {WebView} from '@ionic-native/ionic-webview/ngx';
import {MomentModule} from 'ngx-moment';
import {JwtInterceptor} from '../_interceptor/jwt-Interceptor.service';
import {TokenService} from '../_guards/token.service';
import {AfterLoginService} from '../_guards/after-login.service';
import {BeforeLoginService} from '../_guards/before-login.service';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NgxPermissionsModule, NgxPermissionsStore} from 'ngx-permissions';
import {DateDiffPipe} from '../_pipes/date-diff.pipe';
import {CustomSkeletonLoaderComponent} from './custom-skeleton-loader/custom-skeleton-loader.component';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {Toast} from '@ionic-native/toast/ngx';
import {EmailComposer} from '@ionic-native/email-composer/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {RandomNumberPipe} from '../_pipes/random-number.pipe';
import {SafePipeModule} from 'safe-pipe';
import {CustomButtonComponent} from './custom-button/custom-button.component';
import {CustomTableComponent} from './custom-table/custom-table.component';
import {FileUploadModule} from 'ng2-file-upload';
import {TranslateModule} from '@ngx-translate/core';
import {DatePicker} from '@ionic-native/date-picker/ngx';
import {Ionic4DatepickerModule} from '@logisticinfotech/ionic4-datepicker';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {NoDataComponent} from './no-data/no-data.component';
import {NgCalendarModule} from 'ionic2-calendar';
import {TooltipModule, TooltipOptions} from 'ng2-tooltip-directive';
import {NgSelectModule} from '@ng-select/ng-select';
import {DetailDocumentComponent} from '../pages/admin/mes-documents/detail-document/detail-document.component';
import {MainAlertComponent} from './main-alert/main-alert.component';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {MainPointagesComponent} from './main-pointages/main-pointages.component';
import {FileTransfer, FileUploadOptions, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {NgChatModule} from 'ng-chat';
import {OneSignal} from '@ionic-native/onesignal/ngx';
import {NgxEmojiPickerModule} from 'ngx-emoji-picker';
import {SocieteEquipeEmployeComponent} from './societe-equipe-employe/societe-equipe-employe.component';
import {UploadsFilesComponent} from './uploads-files/uploads-files.component';
import {StatusComponent} from './status/status.component';
import {NgxIonicImageViewerModule} from 'ngx-ionic-image-viewer';
import {AngularDraggableModule} from 'angular2-draggable';
import {SoldesVoyagesRestantesComponent} from './soldes-voyages-restantes/soldes-voyages-restantes.component';
import {MinimizedEmployesCardComponent} from './minimized-employes-card/minimized-employes-card.component';
import {Network} from '@ionic-native/network/ngx';
import {NetworkInterface} from '@ionic-native/network-interface/ngx';
import {SendMailComponent} from "./send-mail/send-mail.component";
import {OpenListFilesComponent} from "./open-list-files/open-list-files.component";
import {NgxLoadingModule} from 'ngx-loading';
import {ClickHandlerDirective} from "../_directive/click-handler.directive";
import {Media, MediaObject} from '@ionic-native/media/ngx';
import {NgxYoutubePlayerModule} from "ngx-youtube-player";
import {MainIonRefresherComponent} from "./main-ion-refresher/main-ion-refresher.component";
import {NativeAudio} from '@ionic-native/native-audio/ngx';
import {Downloader} from "@ionic-native/downloader/ngx";

export const MyDefaultTooltipOptions: TooltipOptions = {
    'hide-delay': 500,
};

export const entryComponents = [
    SendMailComponent,
    OpenListFilesComponent,
];

@NgModule({
    declarations: [
        SharedComponent,
        DateDiffPipe,
        CustomSkeletonLoaderComponent,
        CustomButtonComponent,
        CustomTableComponent,
        NoDataComponent,
        DetailDocumentComponent,
        MainAlertComponent,
        MainPointagesComponent,
        SocieteEquipeEmployeComponent,
        UploadsFilesComponent,
        StatusComponent,
        SoldesVoyagesRestantesComponent,
        MinimizedEmployesCardComponent,
        OpenListFilesComponent,
        ...entryComponents,
        ClickHandlerDirective,
        MainIonRefresherComponent,
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        IonicModule.forRoot(),
        FormsModule,
        HttpClientModule,
        MomentModule,
        NgxSkeletonLoaderModule,
        NgxUiLoaderModule,
        SafePipeModule,
        FileUploadModule,
        TranslateModule.forRoot(),
        ReactiveFormsModule,
        Ionic4DatepickerModule,
        NgBootstrapFormValidationModule.forRoot(),
        NgxPermissionsModule,
        NgCalendarModule,
        TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
        NgSelectModule,
        NgChatModule,
        NgxEmojiPickerModule.forRoot(),
        NgxIonicImageViewerModule,
        AngularDraggableModule,
        NgxLoadingModule.forRoot({}),
        NgxYoutubePlayerModule.forRoot(),
    ],
    exports: [
        IonicModule,
        FormsModule,
        HttpClientModule,
        MomentModule,
        NgxSkeletonLoaderModule,
        DateDiffPipe,
        CustomSkeletonLoaderComponent,
        NgxUiLoaderModule,
        SafePipeModule,
        CustomButtonComponent,
        CustomTableComponent,
        FileUploadModule,
        TranslateModule,
        ReactiveFormsModule,
        Ionic4DatepickerModule,
        NgBootstrapFormValidationModule,
        NoDataComponent,
        NgxPermissionsModule,
        NgCalendarModule,
        TooltipModule,
        NgSelectModule,
        DetailDocumentComponent,
        MainAlertComponent,
        MainPointagesComponent,
        NgChatModule,
        NgxEmojiPickerModule,
        SocieteEquipeEmployeComponent,
        UploadsFilesComponent,
        StatusComponent,
        NgxIonicImageViewerModule,
        AngularDraggableModule,
        SoldesVoyagesRestantesComponent,
        MinimizedEmployesCardComponent,
        OpenListFilesComponent,
        ...entryComponents,
        NgxLoadingModule,
        ClickHandlerDirective,
        NgxYoutubePlayerModule,
        MainIonRefresherComponent
    ],
    entryComponents: [
        DetailDocumentComponent,
        MainAlertComponent,
        OpenListFilesComponent,
        ...entryComponents,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        WebView,
        TokenService,
        BeforeLoginService,
        AfterLoginService,
        DateDiffPipe,
        Toast,
        EmailComposer,
        InAppBrowser,
        RandomNumberPipe,
        NgxPermissionsStore,
        DatePicker,
        Geolocation,
        FileTransfer,
        OneSignal,
        Network,
        NetworkInterface,
        Media,
        NativeAudio,
        Downloader
    ]
})
export class SharedModule {
}
