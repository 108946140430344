import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PointageService {

    private url: any = environment.url_api_backend + '/admin/pointages';
    private url_ressource: any = environment.url_api_backend + '/pointages';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.url_ressource);
    }

    add(data: any) {
        console.log(data);
        return this.http.post(this.url_ressource, data);
    }

    edit(data: any) {
        return this.http.put(this.url_ressource + '/' + data.id, data);
    }

    delete(id: number) {
        return this.http.delete(this.url_ressource + '/' + id);
    }

    getAllByEmploye(id: number) {
        return this.http.get(this.url + '/all-pointages-by-employe/' + id);
    }

    getPointageFormat(equipe_id = null, employe_id = null) {
        return this.http.get(this.url + '/all-pointages-format?equipe_id=' + equipe_id + '&employe_id=' + employe_id);
    }

    getPointageByYearMonth(equipe_id = null, employe_id = null, annee = null, mois = null) {
        return this.http.get(this.url + '/all-pointages-by-year-month?equipe_id=' + equipe_id + '&employe_id=' + employe_id + '&annee=' + annee + '&mois=' + mois);
    }

    getPointageByYearMonthDay(equipe_id = null, employe_id = null, annee = null, mois = null, jour = null) {
        return this.http.get(this.url + '/all-pointages-by-year-month-day?equipe_id=' + equipe_id + '&employe_id=' + employe_id + '&annee=' + annee + '&mois=' + mois + '&jour=' + jour);
    }

    getPointageByDay(equipe_id = null, employe_id = null, date = null) {
        return this.http.get(this.url + '/all-pointages-by-day?equipe_id=' + equipe_id + '&employe_id=' + employe_id + '&date=' + date);
    }

    informationPointageCurrentWeeByEmploye(employe_id = null) {
        return this.http.get(this.url + '/get-information-pointage-current-weed-by-employe/' + employe_id);
    }

}
