import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpResponse, HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TokenService} from '../_guards/token.service';
import {UserService} from '../_services/user.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {GlobalsService} from '../_services/globals.service';

import {map} from "rxjs/operators";
import {MainAlertComponent} from "../shared/main-alert/main-alert.component";
import {ModalController, Platform} from "@ionic/angular";
import {environment} from "../../environments/environment.prod";

@Injectable({
    providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private tokenService: TokenService,
        private userService: UserService,
        private globalService: GlobalsService,
        private modalController: ModalController,
        private platform: Platform,
    ) {
    }

    /*
        Intrcept each request
       */
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = this.tokenService.get();
        let id_utilisateur = '';
        const user = this.userService.getCurrentUser();
        if (user) {
            id_utilisateur = String(user.id);
        }
        if (token) {
            // verif si le token est expirer ou non
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(token);
            const expirationDate = helper.getTokenExpirationDate(token);
            const isExpired = helper.isTokenExpired(token);
            if (isExpired) {
                this.globalService.logout(1);
            } else {
                localStorage.setItem('session_expired', '0');
                request = request.clone({
                    setHeaders: {
                        'user-id': id_utilisateur,
                        Authorization: `Bearer ${token}`/*,
                        'Content-Type': 'application/json'*/
                    }
                });
            }

        } else {
            // No token; proceed request without bearer token
            if (localStorage.getItem('session_expired') && localStorage.getItem('session_expired') == '1') {
                this.globalService.presentToast('Session expirée, veuillez vous reconnecter.', 'danger');
            }
            console.log('No token added to HTTP request');
        }
        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && ~~(event.status / 100) > 3) {
                console.info('HttpResponse::event =', event, ';');
            } else {
                // @ts-ignore
                if (event && event.body && event.body.data && event.body.data.patch && event.body.data.patch.version) {
                    // @ts-ignore
                    let current_patch = event.body.data.patch;
                    if (current_patch.systeme && current_patch.systeme == 'mobile' &&
                        parseFloat(current_patch.version.replace('.', '')) > parseFloat(environment.app_version.replace('.', ''))) {
                        //this.globalService.setCurrentPatch(event.body.data.patch.version);
                        if (this.platform.is('android') && !this.platform.is('mobileweb')) {
                            this.alertNewVerion(current_patch);
                        }
                    }
                }
                //console.info('event =', event, ';');
            }
            return event;
        }))
    }

    async alertNewVerion(current_patch) {
        //console.log('************* patch ************');
        //console.log(current_patch);
        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg: 'Nouvelle version <strong>' + current_patch.version + '</strong> est disponible, merci de la télécharger!',
                title: 'Mise a jour',
                hideButtonDownload: false,
                hideButtonAccepter: true,
                hideButtonAnnuler: true,
                patch: current_patch,
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((modal_result) => {
                console.log(modal_result.data.result);
                if (modal_result.data && modal_result.data.result == 'patch') {
                    var url = this.globalService.getValueEnvironnement('url_api_download_file') + current_patch.path;
                    var windowref = window.open(url, '_blank');
                    return;
                }
            });
        return await modal.present();
    }
}
