import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private url_ressource: any = environment.url_api_backend + '/users';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.url_ressource);
    }

    add(data: any) {
        console.log(data);
        return this.http.post(this.url_ressource, data);
    }

    put(data: any) {
        return this.http.put(this.url_ressource + '/' + data.id, data);
    }

    remove(id: any) {
        return this.http.delete(this.url_ressource + '/' + id);
    }

    /**
     * Retrieve The Current user
     */
    getCurrentUser() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem('user'));
    }

    /**
     * Retrieve The Current user
     */
    getIdSociete() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employe && user.employe.parametre_id) {
            return user.employe.parametre_id;
        } else {
            return null;
        }
    }

    getIdEmployeConnecter() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employe && user.employe.id) {
            return user.employe.id;
        } else {
            return null;
        }
    }

    getEmployeConnecter() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employe && user.employe) {
            return user.employe;
        } else {
            return null;
        }
    }

    getIdPouvoirUserConnecter() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        if (localStorage.getItem('user')) {
            return JSON.parse(localStorage.getItem('user')).pouvoir.id;
        }
        return null;
    }

    getPouvoirUserConnecter() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem('user')).pouvoir.libelle;
    }

    /**
     * Retrieve The Current user
     */
    setCurrentUser(value) {
        localStorage.removeItem('user');
        // localStorage.setItem('user', this.cryptageService.encryptData(value));
        localStorage.setItem('user', JSON.stringify(value));
    }

    clearSessionServer() {
        return this.http.post(environment.url_api_backend + '/clear_session_server', {});
    }
}
