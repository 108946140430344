import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ModalController, NavParams, Platform} from '@ionic/angular';
import {GlobalsService} from "../../_services/globals.service";

@Component({
    selector: 'app-main-alert',
    templateUrl: './main-alert.component.html',
    styleUrls: ['./main-alert.component.scss']
})
export class MainAlertComponent implements OnInit, OnDestroy {

    @Input() title = 'CONFIRMATION';
    @Input() msg = '';
    @Input() hideButtonMinimize = true;
    @Input() hideButtonDownload = true;
    @Input() hideButtonAccepter = false;
    @Input() hideButtonAnnuler = false;
    @Input() patch: any;
    public type_alert = {
        add: 'Voulez-vous confirmer l\'ajout ?',
        edit: 'Voulez-vous confirmer la modification ?',
        delete: 'Voulez-vous la suppression ?'
    };
    public result = false;
    public subscription: Subscription = new Subscription();

    constructor(
        public modalController: ModalController,
        public navParams: NavParams,
        public globalsService: GlobalsService,
    ) {
        if (navParams.get('title')) {
            this.title = navParams.get('title');
        }
        if (navParams.get('msg')) {
            this.msg = navParams.get('msg');
        }
        if (navParams.get('type_alert')) {
            this.msg = this.type_alert[navParams.get('type_alert')];
        }
        if (navParams.get('hideButtonAnnuler')) {
            this.msg = this.type_alert[navParams.get('hideButtonAnnuler')];
        }
        if (navParams.get('hideButtonAccepter')) {
            this.msg = this.type_alert[navParams.get('hideButtonAccepter')];
        }
        if (navParams.get('patch')) {
            this.msg = this.type_alert[navParams.get('patch')];
        }
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    changeAction(action) {
        this.result = action;
        if (action !== 'patch') {
            this.dismiss();
        }
    }

    dismiss() {
        this.modalController.dismiss({
            dismissed: true,
            result: this.result
        });
    }

    telechargerPatch() {
        this.globalsService.downloadFile(this.patch.path, null, true);
    }
}
