import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment.prod';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class EquipeService {

    private url: any = environment.url_api_backend + '/admin/equipes';
    private url_ressource: any = environment.url_api_backend + '/equipes';

    constructor(private http: HttpClient) {
    }

    getAll() {
        return this.http.get(this.url_ressource);
    }

    add(data: any) {
        console.log(data);
        return this.http.post(this.url_ressource, data);
    }

    put(data: any) {
        return this.http.put(this.url_ressource + '/' + data.id, data);
    }

    remove(id: any) {
        return this.http.delete(this.url_ressource + '/' + id);
    }

    getAllEquipes() {
        return this.http.get(this.url + '/all-equipes');
    }

    getEmployesByEquipe(id: number, page: number) {
        return this.http.get(this.url + '/all-employes-by-equipe/' + id + '?page=' + page);
    }

    getAllEquipeWithEmployes() {
        return this.http.get(this.url + '/all-equipes-with-employes');
    }

    getListMailByEquipe(equipe_id) {
        return this.http.get(this.url + '/list-mail-employes-by-equipe/' + equipe_id);
    }

    getPointagesByEquipe(equipe_id, page = 1) {
        return this.http.get(this.url + '/list-pointages-by-equipe/' + equipe_id);
    }

    getAllEmployesWorkWithEmploye(employe_id, page = 1) {
        return this.http.get(this.url + '/all-employes-work-with-employe/' + employe_id + '?page=' + page);
    }

    getAllEmployesWorkUnionSortantWithEmploye(employe_id, page = 1) {
        return this.http.get(this.url + '/all-employes-work-union-sortant-with-employe/' + employe_id + '?page=' + page);
    }

    getAllEquipesBySociete(societe_id) {
        return this.http.get(this.url + '/all-equipes-by-societe/' + societe_id);
    }

    getAllEmployesByEquipe(equipe_id) {
        return this.http.get(this.url + '/all-employes-by-equipe/' + equipe_id);
    }

    getAllEmployesByEquipes(equipes) {
        return this.http.post(this.url + '/all-employes-by-equipes', equipes);
    }

    getAllEmployesInEquipe(equipe_id) {
        return this.http.get(this.url + '/employes-in-equipe/' + equipe_id);
    }

    getEquipesWithDetailsEmployesBySociete(societe_id) {
        return this.http.get(this.url + '/all-equipes-with-details-employes-by-societe/' + societe_id);
    }

}
