export class DocumentModel {
    id?: any = null;
    nom?: any = null;
    type_document_id?: any = null;
    mois?: any = null;
    remarque?: any = null;
    statut_id?: any = null;
    employe_demande_id?: any = null;
    employe_trait_id?: any = null;
    action?: any = null;
    documents?: any = [];
    uploads?: any = [];
}
