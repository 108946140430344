import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GlobalsService} from "../../_services/globals.service";

@Component({
    selector: 'app-minimized-employes-card',
    templateUrl: './minimized-employes-card.component.html',
    styleUrls: ['./minimized-employes-card.component.scss'],
})
export class MinimizedEmployesCardComponent implements OnInit, OnChanges {

    minimizedEmployes: any = [];
    @Input() to_user: any = [];
    @Input() message: any = [];
    @Input() translate_y: any = this.globalsService.translate_y + 'px';
    @Input() translate_x: any = this.globalsService.translate_x + 'px';
    @Output() maximizeCardClicked: EventEmitter<any> = new EventEmitter();

    constructor(public globalsService: GlobalsService) {

    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnInit() {

    }

    maximizeCard() {
        this.maximizeCardClicked.emit({
            to_user: this.to_user,
            message: this.message,
            translate_y: this.translate_y,
            translate_x: this.translate_x,
        });
    }

}
