import {PosteModel} from './poste.model';
import {ContratModel} from './contrat.model';
import {DocumentModel} from './document.model';
import {UserModel} from './user.model';
import {EquipeModel} from './equipe.model';

export class EmployeModel {
    id?: any = null;
    nom?: any = null;
    prenom?: any = null;
    sexe?: any = null;
    age?: any = null;
    pouvoir_id?: any = null;
    equipe_id?: any = null;
    poste_id?: any = [];
    contrat_id?: any = null;
    email?: any = null;
    tel?: any = null;
    photo_profil?: any = null;
    gest_cloture_id?: any = null;
    motif_depart_id?: any = null;
    date_cloture?: any = null;
    parametre_id?: any = null;
    contrat?: ContratModel = new ContratModel();
    contrats?: any = [];
    document?: DocumentModel = new DocumentModel();
    user?: UserModel = new UserModel();
    equipes?: EquipeModel[];
    postes?: PosteModel[];
    fullData?: any = [];
    nom_prenom_email?: any = null;
    equipe?: any = null;
}
