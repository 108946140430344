import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    ViewRef
} from '@angular/core';
import {Subscription} from 'rxjs';
import {GlobalsService} from "../../_services/globals.service";
import {PointageService} from "../../_services/pointage.service";
import * as moment from "moment";
import {KeyValue} from "@angular/common";

@Component({
    selector: 'app-main-pointages',
    templateUrl: './main-pointages.component.html',
    styleUrls: ['./main-pointages.component.scss'],
})
export class MainPointagesComponent implements OnInit, OnDestroy, OnChanges {

    @Input() pointages: any = [];
    @Input() equipe_id: any = null;
    @Input() employe_id: any = null;
    @Input() get_pointage_today: number = 0;
    @Input() refresh_pointages: number = 0;
    //public temp_pointages: any = [];
    public isDataLoaded = false;
    public length_pointage = false;
    public loading = false;
    public modalOpned = [];
    public subscription: Subscription = new Subscription();

    constructor(
        public globalsService: GlobalsService,
        public pointageService: PointageService,
        private ref: ChangeDetectorRef,
    ) {
        /*setInterval(() => {
            if (this.ref && !(this.ref as ViewRef).destroyed) {
                this.ref.detectChanges();
            }
        }, 500);*/
    }

    ngOnInit() {
        //this.temp_pointages = this.pointages;
        //this.getPointageFormat();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.get_pointage_today && changes.get_pointage_today.currentValue &&
            changes.get_pointage_today.currentValue > 0) {
            let year = moment().format('Y');
            let month = moment().format('M');
            let day = moment().format('D');
            this.getPointageByYearMonthDay(year, month, day, null)
        }

        if (changes && (changes.employe_id && changes.employe_id.currentValue ||
            changes.refresh_pointages && changes.refresh_pointages.currentValue ||
            changes.equipe_id && changes.equipe_id.currentValue
        )) {
            this.getPointageFormat();
        }
        //this.getPointageFormat();
    }

    trackByFn(index, item) {
        return index;
    }

    getPointageFormat() {
        let self = this;
        this.loading = true;
        this.subscription = this.pointageService.getPointageFormat(this.equipe_id, this.employe_id).subscribe((res: any) => {
            //self.pointages.push(res.data);
            self.pointages = res.data;
            this.isDataLoaded = true;
            this.loading = false;
            if (
                (self.pointages instanceof Object) && Object.keys(self.pointages).length > 0 ||
                (self.pointages instanceof Array) && self.pointages.length > 0) {
                this.length_pointage = true;
            }
        }, error1 => {
            this.isDataLoaded = true;
            this.loading = false;
        }, () => {

        });
    }

    getPointageByYearMonth(year, month, day, index) {
        let self = this;
        this.loading = true;
        this.subscription = this.pointageService.getPointageByYearMonth(this.equipe_id, this.employe_id, year, month).subscribe((res: any) => {
            this.isDataLoaded = true;
            this.loading = false;
            self.pointages[index][year][month] = res.data;
            //this.temp_pointages = this.pointages;
        }, error1 => {
            this.isDataLoaded = true;
            this.loading = false;
        }, () => {

        });
    }

    getPointageByYearMonthDay(year, month, day, index) {
        let self = this;
        /*this.loading = true;
        if (this.pointages && this.pointages.length > 0) {
            this.loading = true;
            this.subscription = this.pointageService.getPointageByYearMonthDay(this.equipe_id, this.employe_id, year, month, day).subscribe((res: any) => {
                this.isDataLoaded = true;
                this.loading = false;
                this.pointages[year][month][day] = res.data;
            }, error1 => {
                this.isDataLoaded = true;
                this.loading = false;
            }, () => {

            });
        } else {*/
        if (!this.modalOpned[year + month + day]) this.loading = true;
        this.modalOpned[year + month + day] = true;
        this.subscription = this.pointageService.getPointageByYearMonthDay(this.equipe_id, this.employe_id, year, month, day).subscribe((res: any) => {
            this.isDataLoaded = true;
            this.loading = false;
            var tab_day: any = {};
            tab_day[day] = res.data;
            var tab_month: any = {};
            tab_month[month] = tab_day;
            var tab_year: any = {};
            tab_year[year] = tab_month;
            this.pointages[year][month][day] = res.data;
            //this.pointages = tab_year;
            this.length_pointage = true;
        }, error1 => {
            this.isDataLoaded = true;
            this.loading = false;
        }, () => {

        });
        //}
    }

    getNameDay(year, month, day) {
        moment.locale('fr');
        return moment(year + '-' + month + '-' + day).format('dddd');
    }

    originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    reverseKeyOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
    }

    valueOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return a.value.localeCompare(b.value);
    }

    parseValue(value, type = 'float') {
        if (value) {
            if (type == 'float') return parseFloat(value).toFixed(2);
        }
        return 0
    }

    parseTime(time) {
        return moment(time, 'hh:mm:ss').format('hh:mm');
    }
}
