import {ElementRef, Injectable} from '@angular/core';
import {LoadingController, Platform, ToastController} from '@ionic/angular';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment.prod';
import {BehaviorSubject, Subject} from 'rxjs';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

var DateDiff = require('date-diff');
import {EmployeService} from "./employe.service";
import * as moment from "moment";
import {Media, MediaObject} from '@ionic-native/media/ngx';
import {NativeAudio} from '@ionic-native/native-audio/ngx';
import {HttpClient} from "@angular/common/http";
import {Downloader, DownloadRequest, NotificationVisibility} from "@ionic-native/downloader/ngx";
import {DomSanitizer} from "@angular/platform-browser";

declare var require: any
declare const Pusher: any;

const
    disabledDates: Date[] = [
        new Date(1545911005644),
        new Date(),
        new Date(2018, 12, 12), // Months are 0-based, this is August, 10th.
        new Date('Wednesday, December 26, 2018'), // Works with any valid Date formats like long format
        new Date('12-14-2018'), // Short format
    ];

@Injectable({
    providedIn: 'root'
})
export class GlobalsService {

    public colors = ['#24d6ea', '#7044ff', '#10dc60', '#ffce00', '#28e070', '#007bff', '#ffc107', '#dc3545', '#0bb8cc', '#7044ff', '#24d6ea', '#7044ff', '#10dc60', '#ffce00', '#28e070', '#007bff', '#ffc107', '#dc3545', '#0bb8cc', '#7044ff'];
    public images_types = ['png', 'jpg', 'jpeg', 'gif', 'PNG', 'JPG', 'JPEG', 'bmp', 'tiff'];
    public audio_types = ['mp3', 'MP3'];
    public video_types = ['mp4', 'wav'];
    public doc_types = ['pdf', 'doc', 'ppt', 'docx', 'csv', 'excel'];
    public display_button = 'bottom';
    public monthNames: any = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'October', 'November', 'Décembre'];
    public dayhNames: any = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    public isLoading = false;

    datePickerObj: any = {
        inputDate: new Date('2018-08-10'), // default new Date()
        fromDate: new Date('2016-12-08'), // default null
        toDate: new Date('2018-12-28'), // default null
        showTodayButton: true, // default true
        closeOnSelect: true, // default false
        disableWeekDays: [6, 7], // default []
        mondayFirst: true, // default false
        setLabel: 'Select',  // default 'Set'
        todayLabel: 'Aujourd\'huit', // default 'Today'
        closeLabel: 'Fermer', // default 'Close'
        disabledDates, // default []
        titleLabel: 'Selectionner une date', // default null
        monthsList: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October',
            'November', 'December'],
        weeksList: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dateFormat: 'DD/MM/YYYY HH:mm', // default DD MMM YYYY
        clearButton: true, // default true
        momentLocale: 'fr-FR', // Default 'en-US'
        yearInAscending: true, // Default false
        btnCloseSetInReverse: true, // Default false
        btnProperties: {
            expand: 'block', // Default 'block'
            fill: '', // Default 'solid'
            size: '', // Default 'default'
            disabled: '', // Default false
            strong: '', // Default false
            color: '' // Default ''
        },
        arrowNextPrev: {
            nextArrowSrc: 'assets/images/arrow_right.svg',
            prevArrowSrc: 'assets/images/arrow_left.svg'
        }, // This object supports only SVG files.
        highlightedDates: [
            {date: new Date('2019-09-10'), color: '#ee88bf', fontColor: '#fff'},
            {date: new Date('2019-09-12'), color: '#50f2b1', fontColor: '#fff'}
        ], // Default [],
        isSundayHighlighted: {
            fontColor: '#ee88bf' // Default null
        } // Default {}
    };

    // current nombre_demandes
    nbrDemandeValue = new Subject();
    private _refreshNeeded$ = new Subject<void>();
    get refreshNeeded$() {
        return this.nbrDemandeValue;
    }

    //current patch
    //list users connected to app
    //current_patch = new Subject();
    private current_patch = new BehaviorSubject<any[]>([]);

    setCurrentPatch(value) {
        // setTimeout(() => {
        this.current_patch.next(value);
        // }, 0)
    }

    currentPatch() {
        return this.current_patch.asObservable();
    }

    //list users connected to app
    usersConnected$ = new BehaviorSubject<any[]>([]);

    //list users connected to app
    minimizedEmployes$ = new BehaviorSubject<any[]>([]);

    // minimized employes cards
    public translate_y: any = 0;
    public translate_x: any = -5;
    public pas_translate_y: any = 75;
    public pas_translate_x: any = -75;

    minimizedEmployesValue = new Subject();
    private _refreshNeededMinimizedEmployes$ = new Subject<void>();
    get refreshNeededMinimizedEmployes$() {
        return this.minimizedEmployesValue;
    }

    setMinimizedEmployes(value = {}) {
        //console.log(value);
        this.minimizedEmployesValue.next(value);
        this._refreshNeededMinimizedEmployes$.next();
    }

    //list users connected to app
    lastMessageReceived$ = new BehaviorSubject<any[]>([]);

    constructor(
        public loadingController: LoadingController,
        public router: Router,
        public toastController: ToastController,
        private iab: InAppBrowser,
        private employeService: EmployeService,
        private media: Media,
        private nativeAudio: NativeAudio,
        private http: HttpClient,
        private downloader: Downloader,
        private platform: Platform,
        public sanitizer: DomSanitizer
    ) {

    }

    /**
     * Retrieve The Current user
     */
    getCurrentUser() {
        // return this.userValue.asObservable();
        // return this.cryptageService.decryptData(localStorage.getItem('user'));
        return JSON.parse(localStorage.getItem('user'));
    }

    /**
     * Retrieve The Current user
     */
    setCurrentUser(value) {
        localStorage.removeItem('user');
        // localStorage.setItem('user', this.cryptageService.encryptData(value));
        localStorage.setItem('user', JSON.stringify(value));
    }

    logout(session_expired = 0) {
        let souvenir_moi = localStorage.getItem('souvenir_moi') || null;
        localStorage.clear();
        if (session_expired && session_expired != 0) {
            localStorage.setItem('session_expired', '1');
        }
        if (souvenir_moi) {
            localStorage.setItem('souvenir_moi', souvenir_moi);
        }
        localStorage.setItem('show_slides', 'true');
        this.router.navigateByUrl('auth');
    }

    async presentToast(msg = 'Opération effectué avec succés', type = 'success', duree = 3000) {
        const toast = await this.toastController.create({
            message: msg,
            color: type,
            duration: duree
        });
        toast.present();
    }

    async presentLoading(msg = 'S\'il vous plaît, attendez...', duree = 3000) {
        this.isLoading = true;
        return await this.loadingController.create({
            message: msg,
            duration: duree,
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log('abort presenting'));
                }
            });
        });
    }

    async presentDismiss() {
        this.isLoading = false;
        return await this.loadingController.dismiss().then(() => console.log('dismissed'));
    }

    spliceFromObject(objects, object_id) {
        const removeIndex = objects.map(function (item) {
            return item.id;
        }).indexOf(object_id);

// remove object
        objects.splice(removeIndex, 1);
        return objects;
    }

    getEnvironementProd() {
        return environment;
    }

    setNbrDemande(value = 0) {
        this.nbrDemandeValue.next(value);
        this._refreshNeeded$.next();
    }

    /**
     * diff.years(); // ===> 1.9
     * diff.months(); // ===> 23
     * diff.days(); // ===> 699
     * diff.weeks(); // ===> 99.9
     * diff.hours(); // ===> 16776
     * diff.minutes(); // ===> 1006560
     * diff.seconds(); // ===> 60393600
     * @param date_du
     * @param date_au
     * @param format
     * @param toFixed
     */
    diffDate(date_du, date_au, format = 'hours', toFixed = 2) {
        if (date_du && date_au) {
            const diff = new DateDiff(new Date(date_du), (new Date(date_au)));
            return (Math.abs(diff[format]())).toFixed(toFixed);
        }
        return 0;
    }

    getValueEnvironnement(indice) {
        return environment[indice];
    }

    translateMinimizeCard(value = this.pas_translate_y) {
        this.translate_y = parseInt(this.translate_y) + value;
        return String(this.translate_y) + 'px';
    }

    translateX() {
        return String(this.translate_x) + 'px';
    }

    decrementTranslateX(value = this.pas_translate_x) {
        this.translate_x = parseInt(this.translate_x) + value;
    }

    resetTranslateX() {
        this.translate_x = 0;
    }

    decrementTranslateY(value = this.pas_translate_y) {
        this.translate_y = parseInt(this.translate_y) - value;
    }

    resetTranslateY() {
        this.translate_y = -70;
    }

    setAllParams() {
        this.employeService.getAllParams().subscribe((data: any) => {
            console.log('*************** getAllParams *************');
            localStorage.setItem('params', JSON.stringify(data.data));
            return data.data;
        }, error1 => {
            console.log(error1);
            return false;
        }, () => {

        });
    }

    getAllParams() {
        if (localStorage.getItem('params')) {
            return JSON.parse(localStorage.getItem('params'));
        } else {
            return this.setAllParams();
        }

    }


    logScrollStart() {
        //console.log('********************* logScrollStart *******************');
        //console.log("logScrollStart : When Scroll Starts");
    }

    logScrolling(event) {
        //console.log("logScrolling : When Scrolling");
        if (event.detail.scrollTop <= 4) {
            this.display_button = 'bottom';
        }
        if (event.detail.scrollTop >= 500) {
            this.display_button = 'top';
        }
        //console.log(event.detail.scrollTop);
    }

    logScrollEnd() {
        //console.log('********************* logScrollEnd *******************');
        //console.log(this.display_button);
        //console.log("logScrollEnd : When Scroll Ends");
    }

    ScrollTo(content) {
        if (this.display_button == 'bottom') {
            this.ScrollToBottom(content);
        } else {
            this.ScrollToTop(content);
        }
    }

    ScrollToBottom(content) {
        content.scrollToBottom(1500);
    }

    ScrollToTop(content) {
        content.scrollToTop(1500);
    }

    ScrollToPoint(content, X, Y) {
        content.scrollToPoint(X, Y, 1500);
    }

    getNombreDemandeEnCours() {
        this.employeService.getNombreDemandeEnCours().subscribe((res: any) => {
            this.setNbrDemande(parseInt(res.data.documents) + parseInt(res.data.voyages));
        }, error1 => {
            console.log(error1);
        }, () => {
        });
    }

    genererColor(length = 6) {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let randomstring = '';
        for (let i = 0; i < length; i++) {
            const rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum, rnum + 1);
        }
        randomstring = '#' + randomstring;
        return randomstring;
    }

    genererPassword(length = 6) {
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let randomstring = '';
        for (let i = 0; i < length; i++) {
            const rnum = Math.floor(Math.random() * chars.length);
            randomstring += chars.substring(rnum, rnum + 1);
        }
        //randomstring = '#' + randomstring;
        return randomstring;
    }

    getExtensionFile(file) {
        return file.split('.').pop();
    }

    compareWithFn = (o1, o2) => {
        return o1 && o2 ? o1.id === o2.id : o1 === o2;
    };

    isLinkYoutube(path) {
        if (path && path.includes('watch?v=')) {
            return (path.split('watch?v='))[1];
        }
        if (path && path.includes('youtu.be/')) {
            return (path.split('youtu.be'))[1];
        }
        return false;
    }

    setDateFormat(event, format = 'date') {
        if (format == 'date') {
            return moment(event.detail.value).format('YYYY-MM-DD');
        } else {
            return moment(event.detail.value).format('YYYY-MM-DD HH:MM');
        }
    }

    playSoundRefresher() {
        //const file: MediaObject = this.media.create(environment.url_api_download_file + environment.default_audio_refresher);
        //file.play();
        //let unique_id = this.genererPassword();
        this.nativeAudio.preloadSimple('sound1', environment.default_audio_refresher);
        this.nativeAudio.play('sound1');
    }

    playSoundNewMessageRecieved() {
        //const file: MediaObject = this.media.create(environment.url_api_download_file + environment.default_audio_message_notifcation);
        //file.play();
        //let unique_id = this.genererPassword();
        this.nativeAudio.preloadSimple('sound2', environment.default_audio_message_notifcation);
        this.nativeAudio.play('sound2');

    }

    getDataInObjectByIndex(data: any, index: any) {
        if (Object.keys(data).length > 0 && data.constructor === Object) {
            return (<any>Object.values(data)[index]);
        }
    }

    getSafeURL(path) {
        return this.sanitizer.bypassSecurityTrustResourceUrl('https://docs.google.com/viewer?url=' + path + '&embedded=true');
    }

    getMIMEtype(extn) {
        const ext = extn.toLowerCase();
        const MIMETypes = {
            txt: 'text/plain',
            docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            doc: 'application/msword',
            pdf: 'application/pdf',
            jpg: 'image/jpeg',
            bmp: 'image/bmp',
            png: 'image/png',
            xls: 'application/vnd.ms-excel',
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            rtf: 'application/rtf',
            ppt: 'application/vnd.ms-powerpoint',
            pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            csv: 'text/csv',
            apk: 'application/apk'
        };
        return MIMETypes[ext];
    }

    /***
     * function pour télécharger une fichier from url
     * _self : in same window
     * _blank : in another window
     * @param path_file
     */
    downloadFile(path_file: string, file_name = null, direct_download = false) {
        let extension = path_file.split('.').pop();
        if (!file_name) {
            file_name = 'aggeweb-grh.' + extension;
        }
        // browser.close();
        // window.open(path_file, '_blank', 'location=no');
        var url = this.getValueEnvironnement('url_api_download_file') + '/' + path_file;

        //console.log('************* platform ***************');
        //console.log(this.platform.platforms());
        // if platform is android
        if (this.platform.is('android') && !this.platform.is('mobileweb') &&
            !this.platform.is('desktop') && direct_download == false) {
            //return;
            var request: DownloadRequest = {
                uri: url,
                title: file_name,
                description: '',
                mimeType: this.getMIMEtype(extension),
                visibleInDownloadsUi: true,
                notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
                destinationInExternalFilesDir: {
                    dirType: 'Downloads',
                    subPath: file_name
                }
            };

            this.downloader.download(request)
                .then((location: string) => {
                    this.presentToast('Fichier télècharger avec sucés', 'success');
                    console.log('File downloaded at:' + location)
                })
                .catch((error: any) => console.error(error));
        } else {
            var windowref = window.open(url, '_blank');
        }

    }
}
