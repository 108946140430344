import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ChatComponent} from './chat.component';
import {MessageComponent} from './message/message.component';
import {MessageEquipeComponent} from "./message-equipe/message-equipe.component";

const routes: Routes = [
    {
        path: '',
        component: ChatComponent,
    },
    {
        path: 'message/:equipe_id',
        component: MessageComponent,
    },
    {
        path: 'message-equipe',
        component: MessageEquipeComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ChatRoutingModule {
}
