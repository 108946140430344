import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalsService} from "../../_services/globals.service";

@Component({
    selector: 'app-no-data',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent implements OnInit {

    @Input() text: any = 'Pas des données trouvées';
    @Input() hength: any = '90%';
    @Input() text_size: any = '13.5px';
    @Input() showImg: boolean = true;
    @Input() showText: boolean = true;
    @Output() doRefresh: EventEmitter<any> = new EventEmitter<any>();

    constructor(private globalsService: GlobalsService) {
    }

    ngOnInit() {

    }

    notifRefresh(event) {
        this.globalsService.playSoundRefresher();
        this.doRefresh.emit(event);
        setTimeout(() => {
            //console.log('Async operation has ended');
            event.target.complete();
        }, 2000);
    }
}
