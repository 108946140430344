import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {MessageModel} from "../../../../_models/message.model";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../_services/user.service";
import {ChatService} from "../../../../_services/chat.service";
import {EquipeService} from "../../../../_services/equipe.service";
import {GlobalsService} from "../../../../_services/globals.service";
import {IonContent, ModalController, NavController, NavParams} from "@ionic/angular";
import {DataService} from "../../../../_services/data.service";
import {environment} from "../../../../../environments/environment.prod";
import {ViewerModalComponent} from "ngx-ionic-image-viewer";
import {MessageEquipe} from "../message-equipe/message-equipe.component";
import {DomSanitizer} from '@angular/platform-browser';
import {MainAlertComponent} from "../../../../shared/main-alert/main-alert.component";

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {

    toggled = false;
    loading = false;
    public img_from: any;
    public img_to: any;
    public current_employe: any = null;
    public to_user: any = null;
    public page = 1;
    public cpt_msg = 0;
    public last_id_msg = null;
    public old_page = 1;
    public messages: MessageModel[] = [];
    public message: MessageModel = new MessageModel();
    public subscription: Subscription = new Subscription();
    state = '';
    size: any = null;
    position: any = null;
    is_opened = false;
    minimize = false;
    public pre_upload: any;
    public show_pre_upload = false;
    public files = [];
    public tab_users_connected = [];
    public minimizedEmployes = [];
    public temp_message: any = {};
    player: YT.Player;
    private id: string = 'qDuKsiwS5xw';
    // @ts-ignore
    @ViewChild(IonContent) content: IonContent

    constructor(
        private router: Router,
        public userService: UserService,
        private chatService: ChatService,
        private equipeService: EquipeService,
        public globalsService: GlobalsService,
        private navCtrl: NavController,
        private route: ActivatedRoute,
        private dataService: DataService,
        public navParams: NavParams,
        public modalController: ModalController,
        private elementRef: ElementRef,
        public sanitizer: DomSanitizer,
    ) {
        if (navParams.get('to_user')) {
            this.to_user = navParams.get('to_user');
        }
        if (navParams.get('is_opened')) {
            this.is_opened = navParams.get('is_opened');
        }
        if (navParams.get('tab_users_connected')) {
            this.tab_users_connected = navParams.get('tab_users_connected');
        }

    }

    ngOnInit() {
        let self = this;
        //alert( this.route.snapshot.queryParams.equipe_id)
        //alert( this.to_user.equipe_id)
        this.route.params.subscribe(params => {
            if (params['equipe_id']) this.to_user.equipe_id = params['equipe_id'];
        });
        this.loading = true;
        this.img_from = 'assets/icon/user1.png';
        this.img_to = 'assets/icon/user2.png';
        this.current_employe = this.userService.getEmployeConnecter();
        if (this.current_employe && this.current_employe.photo_profil) {
            this.img_from = environment.url_api_download_file + this.current_employe.photo_profil;
        }

        // get list users conneceted to app
        this.globalsService.usersConnected$.subscribe((next: any) => {
            this.tab_users_connected = next;
        });


        //set active chat
        localStorage.setItem('active_chat', this.to_user.unique_id);
        // get all messages
        if (this.to_user && this.to_user.unique_id && this.to_user.unique_id.includes('employe')) {
            this.getAllMessagesEmploye(this.to_user.employe_id);
        } else {
            this.getAllMessagesEquipe(this.to_user.equipe_id);
        }

        if (localStorage.getItem('minimizedEmployes')) {
            this.minimizedEmployes = JSON.parse(localStorage.getItem('minimizedEmployes'));
        }

        self.globalsService.lastMessageReceived$.subscribe((next: any) => {
            console.log('************* next *************');
            console.log(next);
            if (next && next.message) {
                if (next.unique_id.includes(localStorage.getItem('active_chat'))) {
                    /*if (next.to && next.to == this.userService.getIdEmployeConnecter()) {
                        self.globalsService.playSoundNewMessageRecieved();
                    }*/
                    self.messages.push(next);
                    self.scrollToBottom();
                }
            }
        })
    }

    scrollToBottom(scroll_to = 'sroll_to_bottom'): void {
        try {
            if (scroll_to == 'sroll_to_bottom') {
                this.globalsService.display_button = 'bottom';
                this.globalsService.ScrollTo(this.content);
            }
        } catch (err) {
        }
    }

    getAllMessagesEquipe(equipe_id, scroll_to = 'sroll_to_bottom') {
        const self = this;
        //if (this.cpt_msg == 0) this.loading = true;
        this.subscription = this.chatService.getMessagesEquipe(this.page, this.to_user.equipe_id).subscribe(
            (res: any) => {
                this.loading = false;
                if (res.data && res.data.data && res.data.data.length > 0) {
                    if (res.data.data.length == res.data.per_page) {
                        this.page++;
                    }
                    if (!localStorage.getItem('active_chat')) {
                        localStorage.setItem('active_chat', res.data.data[0].unique_id);
                    } else {
                        localStorage.removeItem('active_chat');
                        localStorage.setItem('active_chat', res.data.data[0].unique_id);
                    }
                    //this.page++;
                    res.data.data.forEach(function (v, i) {
                        if (self.messages.findIndex(item => item.id == v.id) < 0) {
                            self.messages.unshift(v);
                        }
                    });
                    this.scrollToBottom(scroll_to);
                }
            }, (error) => {
                this.loading = false;
            });
    }

    getAllMessagesEmploye(to, scroll_to = 'sroll_to_bottom') {
        const self = this;
        //if (this.cpt_msg == 0) this.loading = true;
        this.subscription = this.chatService.getMessages(this.page, to).subscribe(
            (res: any) => {
                this.loading = false;
                if (res.data && res.data.data && res.data.data.length > 0) {
                    //this.page++;
                    res.data.data.forEach(function (v, i) {
                        if (self.messages.findIndex(item => item.id == v.id) < 0) {
                            self.messages.unshift(v);
                        }
                    });
                    if (res.data.data.length == res.data.per_page) {
                        this.page++;
                    }
                    this.scrollToBottom(scroll_to);
                }
                // this.scrollToBottom();
            }, (error) => {
                this.loading = false;
            });
    }

    sendMessage() {
        //alert(this.to_user.equipe_id);
        this.message.from = this.userService.getIdEmployeConnecter();
        this.message.to = this.to_user.employe_id;
        this.message.employe_id = this.userService.getIdEmployeConnecter();
        this.message.equipe_id = this.to_user.equipe_id;
        this.message.equipe_employe_id = this.to_user.equipe_employe_id;
        this.message.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
        //this.messages.unshift(message);
        if (this.to_user.unique_id.includes('employe')) {
            this.sendMessageEmploye()
        } else {
            this.sendMessageEquipe();
        }
    }

    sendMessageEquipe() {
        if (!this.message && !this.pre_upload) {
            this.globalsService.presentToast('Vous devez saisir un message', 'danger');
            return false;
        } else {
            const formData = new FormData();
            formData.append('message', JSON.stringify(this.message));
            formData.append('file', this.files[0]);
            this.loading = true;
            this.chatService.sendMessagesEquipe(formData).subscribe((res: any) => {
                this.loading = false;
                if (res.data && res.data.id) {
                    // this.message.id = res.data.id;
                    this.last_id_msg = res.data.id;
                }
                //if (res.data && res.data.path) this.message.path = res.data.path;
                //if (res.data && res.data.type_file) this.message.type_file = res.data.type_file;
                this.message = res.data;
                this.messages.push(this.message);
                this.scrollToBottom();
                this.clearFile();
            }, error1 => {
                this.loading = false;
                this.globalsService.presentToast('Message n\'est pas envoyé', 'danger');
                this.message = new MessageEquipe();
            }, () => {
                this.loading = false;
            });
        }
    }

    sendMessageEmploye() {
        if (!this.message.from) {
            this.globalsService.presentToast('Le champ from est vide', 'danger');
            return false;
        } else if (!this.message.to) {
            this.globalsService.presentToast('Le to from est vide', 'danger');
            return false;
        } else if (!this.message.message) {
            this.globalsService.presentToast('Le champ message est vide', 'danger');
            return false;
        } else {
            const formData = new FormData();
            formData.append('message', JSON.stringify(this.message));
            formData.append('file', this.files[0]);
            this.loading = true;
            this.chatService.sendMessage(formData).subscribe((res: any) => {
                this.loading = false;
                if (res.data && res.data.id) {
                    this.message.id = res.data.id;
                    this.last_id_msg = res.data.id;
                }
                //if (res.data && res.data.message) this.message.message = res.data.message;
                //if (res.data && res.data.path) this.message.path = res.data.path;
                ////if (res.data && res.data.type_file) this.message.type_file = res.data.type_file;
                this.message = res.data;
                // this.scrollToBottom();
                this.messages.push(this.message);
                this.scrollToBottom();
                this.clearFile();
            }, error1 => {
                this.loading = false;
                //console.log(error1);
            }, () => {
                this.loading = false;
            });
            this.message = new MessageModel();
            //console.log(this.message);
        }
    }

    getMoreEMessagePaginateByPage(event) {
        setTimeout(() => {
            if (this.to_user && this.to_user.unique_id && this.to_user.unique_id.includes('employe')) {
                this.getAllMessagesEmploye(this.to_user.employe_id, 'sroll_to_top');
            } else {
                this.getAllMessagesEquipe(this.to_user.equipe_id, 'sroll_to_top');
            }
            //console.log('Async operation has ended');
            event.target.complete();
        }, 500);
    }

    trackByFn(index, item) {
        return index;
    }

    handleSelection(event) {
        this.message.message += event.char;
    }

    goBack(minimize = false) {
        //remove active chat
        localStorage.removeItem('active_chat');
        this.modalController.dismiss({
            dismissed: true,
            to_user: this.to_user,
            message: this.message,
            minimize
        });
    }

    public minimizeCard(minimize = true) {
        // if (minimize == true) {
        let self = this;
        if (minimize == true) {
            this.to_user.equipe_id = 0;
            this.to_user.nbr_msg_not_readet = 0;
            const index = this.minimizedEmployes.findIndex(element => element.to_user.unique_id == this.to_user.unique_id);
            // change position if screen plain by minimize card
            if (self.globalsService.translate_y >= 455) {
                self.globalsService.decrementTranslateX();
                self.globalsService.resetTranslateY();
            }
            if (index < 0) {
                if (self.minimizedEmployes.length > 0) {
                    self.globalsService.translate_y = self.minimizedEmployes[self.minimizedEmployes.length - 1].translate_y;
                }
                this.minimizedEmployes.push({
                    to_user: this.to_user,
                    message: this.message,
                    translate_y: this.globalsService.translateMinimizeCard(),
                    translate_x: self.globalsService.translateX(),
                });
                //localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
                //this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
            } else {
                if (self.minimizedEmployes.length > 0) {
                    self.globalsService.translate_y = self.minimizedEmployes[self.minimizedEmployes.length - 1].translate_y;
                }
                this.minimizedEmployes[index].to_user = this.to_user;
                //localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
                //this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
            }
            //localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
            //this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
            // /console.log(self.minimizedEmployes);
        } else {
            // change position if screen plain by minimize card
            if (self.globalsService.translate_y >= 455) {
                self.globalsService.decrementTranslateX();
                self.globalsService.resetTranslateY();
            } else {
                self.globalsService.resetTranslateX();
            }
            const index = this.minimizedEmployes.findIndex(element => element.to_user.unique_id == this.to_user.unique_id);
            this.minimizedEmployes.splice(index, 1);
            this.globalsService.resetTranslateY();
            this.minimizedEmployes.forEach(function (v, i) {
                v.translate_y = self.globalsService.translateMinimizeCard();
                v.translate_x = self.globalsService.translateX();
            });
            //localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
           // this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
        }
        //}
        localStorage.setItem('minimizedEmployes', JSON.stringify(self.minimizedEmployes));
        this.globalsService.setMinimizedEmployes(this.minimizedEmployes);
        this.goBack(minimize);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    uploadFile(event) {
        const file = event.target.files[0];
        let extension_file = this.globalsService.getExtensionFile(file.name);
        //console.log('file : ' + extension_file);
        this.files.push(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            this.message.message = file.name;
            this.message.type_file = extension_file;
            if (this.globalsService.images_types.includes(extension_file)) {
                this.pre_upload = reader.result;
            } else if (this.globalsService.audio_types.includes(extension_file) || this.globalsService.video_types.includes(extension_file)) {
                this.pre_upload = environment.default_img_audio_video;
            } else {
                this.pre_upload = environment.default_img_doc_files;
            }
        };
    }

    clearFile() {
        this.files = [];
        this.pre_upload = null;
        this.message = new MessageEquipe();
        this.temp_message = {};
    }

    async openViewer(path) {
        //console.log(path);
        const modal = await this.modalController.create({
            component: ViewerModalComponent,
            componentProps: {
                src: path
            },
            cssClass: 'ion-img-viewer',
            keyboardClose: true,
            showBackdrop: true
        });

        return await modal.present();
    }

    savePlayer(player) {
        this.player = player;
        //console.log('player instance', player);
    }

    onStateChange(event) {
        //console.log('player state', event.data);
    }

    async deleteMessage(msg, index) {
        const modal = await this.modalController.create({
            component: MainAlertComponent,
            cssClass: 'my-custom-modal-main-alert-size',
            backdropDismiss: false,
            componentProps: {
                msg: 'Voulez-vous supprimer ce <strong>message</strong> ?'
            }
        });

        // on dismiss
        modal.onDidDismiss()
            .then((data) => {
                //console.log(data.data.result);
                if (data.data && data.data.result) {
                    this.loading = true;
                    this.subscription = this.chatService.removeMessages(msg.id).subscribe((response: any) => {
                        this.loading = false;
                        this.messages = this.globalsService.spliceFromObject(this.messages, msg.id);
                        this.globalsService.presentToast('Message supprimé avec succès');
                    }, error1 => {
                        this.loading = false;
                        this.globalsService.presentToast('Une erreur qui se produit merci de réussi plus tard', 'danger');
                    }, () => {

                    });
                }
            });
        return await modal.present();
    }
}
