import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AfterLoginService} from './_guards/after-login.service';
import {BeforeLoginService} from './_guards/before-login.service';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full'
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AfterLoginService]
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
        canActivate: [BeforeLoginService]
    },
    {
        path: 'error',
        loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)
    },
    {
        path: 'slides-bienvenue',
        loadChildren: () => import('./pages/admin/slides-bienvenue/slides-bienvenue.module').then(m => m.SlidesBienvenueModule)
    },
    {path: '**', redirectTo: '/error', pathMatch: 'full'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
