import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {TokenService} from './token.service';
import {Observable} from 'rxjs';

@Injectable()
export class AfterLoginService implements CanActivate {


    constructor(private Token: TokenService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if (this.Token.loggedIn()) {
            if (localStorage.getItem('show_slides')) {
                return true;
            } else {
                this.router.navigate(['/slides-bienvenue']);
            }
            return true;
        } else {
            this.router.navigate(['/auth']);
        }

    }
}
