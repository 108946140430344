import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FileLikeObject, FileUploader} from "ng2-file-upload";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-uploads-files',
    templateUrl: './uploads-files.component.html',
    styleUrls: ['./uploads-files.component.scss'],
})
export class UploadsFilesComponent implements OnInit, OnDestroy {

    @Output() selectedFiles: EventEmitter<any> = new EventEmitter();
    public subscription: Subscription = new Subscription();
    public fileUploader: FileUploader = new FileUploader({});
    public hasBaseDropZoneOver = false;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getFiles(): FileLikeObject[] {
        return this.fileUploader.queue.map((fileItem) => {
            return fileItem.file;

        });
    }

    uploadFiles() {
        /*const files = this.getFiles();
        const requests = [];
        const formData = new FormData();
        files.forEach((file, i) => {
            formData.append('file' + i, file.rawFile, file.name);
        });*/

        this.selectedFiles.emit(this.getFiles());
    }
}
