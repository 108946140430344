import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentModel} from "../../_models/document.model";
import {Subscription} from "rxjs";
import {ModalController, NavParams} from "@ionic/angular";
import {GlobalsService} from "../../_services/globals.service";
import {Router} from "@angular/router";
import {InAppBrowser} from "@ionic-native/in-app-browser/ngx";
import {environment} from "../../../environments/environment.prod";

@Component({
  selector: 'app-open-list-files',
  templateUrl: './open-list-files.component.html',
  styleUrls: ['./open-list-files.component.scss'],
})
export class OpenListFilesComponent implements OnInit, OnDestroy {

  @Input() libelle_document: string = '';
  @Input() document: DocumentModel = new DocumentModel();

  public subscription: Subscription = new Subscription();

  constructor(
      public navParams: NavParams,
      public modalController: ModalController,
      public globalsService: GlobalsService,
      public router: Router,
      private io: InAppBrowser,
  ) {
  }

  ngOnInit() {
    if (this.navParams.get('libelle_document')) {
      this.libelle_document = this.navParams.get('libelle_document');
    }
    if (this.navParams.get('document')) {
      this.document = this.navParams.get('document');
    }
    console.log(this.document);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  open(path) {
    /*window.open(path, '_system');
    return false;*/

    const url = encodeURIComponent(environment.url_api_download_file + path);
    this.io.create('https://docs.google.com/viewer?url=' + url);
  }
}
