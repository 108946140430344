import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {

  @Input() colum: any = [];
  @Input() data: any = [];
  constructor() { }

  ngOnInit() {}

}
