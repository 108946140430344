import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ParametreService} from '../../_services/parametre.service';
import {EmployeService} from '../../_services/employe.service';
import {EquipeService} from '../../_services/equipe.service';
import {Subscription} from 'rxjs';
import {EmployeModel} from '../../_models/employe.model';
import {EquipeModel} from '../../_models/equipe.model';
import {ParametreModel} from '../../_models/parametre.model';
import {GlobalsService} from '../../_services/globals.service';
import {UserService} from "../../_services/user.service";

@Component({
    selector: 'app-societe-equipe-employe',
    templateUrl: './societe-equipe-employe.component.html',
    styleUrls: ['./societe-equipe-employe.component.scss'],
})
export class SocieteEquipeEmployeComponent implements OnInit, OnDestroy, OnChanges {

    @Output() selectedSociete: EventEmitter<any> = new EventEmitter();
    @Output() selectedEquipe: EventEmitter<any> = new EventEmitter();
    @Output() selectedEmploye: EventEmitter<any> = new EventEmitter();
    public subscription: Subscription = new Subscription();
    public projetForm: FormGroup;
    submitted = false;
    loading = false;
    is_tous_selectted = false;
    public employes_selected: any = [];
    public employes: EmployeModel[] = [];
    @Input() employe: any;
    @Input() show_employe = true;
    public equipes: EquipeModel[] = [];
    @Input() equipe: any = null;
    @Input() show_equipe = true;
    public societes: ParametreModel[] = [];
    @Input() societe: any = [];
    @Input() show_societe = true;
    @Input() selectorItemEmploye: any = 'id';
    @Input() multiple_societe: true;
    @Input() multiple_equipe: true;
    @Input() multiple_employe: true;
    @Input() show_all_equipe: true;
    @Input() show_all_employe: true;
    compareWith: (o1: any, o2: any) => boolean;
    constructor(
        private societeService: ParametreService,
        private employeService: EmployeService,
        public userService: UserService,
        public equipeService: EquipeService,
        private formBuilder: FormBuilder,
        private globalsService: GlobalsService,
    ) {
        this.compareWith = this.compareWithFn;
        this.societe = this.userService.getIdSociete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        //console.log(changes);
        /*if (changes.show_employe && changes.show_employe.currentValue !== this.show_employe) {
            if (this.societe && !this.equipe) {
                this.getAllEquipesBySociete();
            } else if (this.societe && this.equipe) {
                this.getAllEquipesBySociete();
                this.getListEmployesByStaff();
            } else {
                this.getAllEmployes();
            }
        }*/
    }

    ngOnInit() {
        //console.log('************** Spciete *****************');
        //console.log(this.societe);
        //console.log('************** Equipe *****************');
        //console.log(this.equipe);
        //console.log('************** Employe *****************');
        //console.log(this.employe);
        if (this.societe && !this.equipe) {
            this.getAllEquipesBySociete();
        } else if (this.societe && this.equipe) {
            this.getAllEquipesBySociete();
            this.getListEmployesByStaff();
        } else {
            this.getAllEmployes();
        }
        /**
         * get all societes
         */
        this.getAllSocietes();

        this.projetForm = this.formBuilder.group({
            societe: ['', Validators.required],
            equipe: ['', Validators.required],
            employe: ['', Validators.required],
        });

        // set default value
        //  this.projetForm.controls.equipe.setValue(2);


    }

    compareTipoDeActividad(first: any, second: ParametreModel): boolean {

        return first && second ? first.id === second.id : first === second;

    }

    compareWithFn = (o1, o2) => {
        return o1 === o2;
    };

    /**
     * get all societes
     */
    getAllSocietes() {
        this.subscription = this.societeService.getAll().subscribe((res: any) => {
            if (res.data.length > 0) {
                this.societes = res.data;
            } else {
                this.getAllEquipesBySociete();
            }
        }, error1 => {

        }, () => {

        });
    }

    /**
     * get all equipes
     */
    getAllEquipesBySociete() {
        this.subscription = this.equipeService.getAllEquipesBySociete(this.societe).subscribe((res: any) => {
            //console.log(res);
            if (res.data.length > 0) {
                this.equipes = res.data;
            }
        }, error1 => {

        }, () => {

        });
    }

    /**
     * get all employes
     */
    getAllEmployes() {
        this.subscription = this.employeService.getAll().subscribe((res: any) => {
            this.employes = res.data;
        }, error1 => {

        }, () => {

        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getListStaffBySociete() {
        // set societe selectionner
        this.selectedSociete.emit(this.societe);

        this.loading = true;
        this.equipeService.getAllEquipesBySociete(this.societe).subscribe((response: any) => {
            this.equipes = response.data;
            this.loading = false;
        }, error1 => {
            this.loading = false;
        }, () => {

        });
    }

    getListEmployesByStaff() {
        // set equipe selectionner
        const self = this;
        this.selectedEquipe.emit(this.equipe);

        this.loading = true;
        this.equipeService.getAllEmployesInEquipe(this.equipe).subscribe((response: any) => {
            self.employes = [];
            if (response.data && response.data.length > 0) {
                response.data.forEach(function (v, i) {
                    v.nom_prenom_email = v.nom + ';' + v.prenom + ';' + v.email;
                    self.employes.push(v);
                });
            }
            this.loading = false;
        }, error1 => {
            this.loading = false;
        }, () => {

        });
    }

    selectAllEmployees(event) {
        //console.log(event.detail.value);
        const self = this;
        if ([event.detail.value].includes('all')) {
            this.is_tous_selectted = true;
            this.employes.forEach(function (v, i) {
                self.employes_selected.push(v.id);
            });
        }
        if (this.is_tous_selectted && !self.employes_selected.includes('all')) {
            this.is_tous_selectted = false;
            self.employes_selected = [];
        }

        this.selectedEmploye.emit(this.employes_selected);
    }

    setEmploye(event) {
        //console.log(event);
        this.selectedEmploye.emit(this.employe);
    }

    trackByFn(index, item) {
        return index;
    }

    compareById(o1, o2) {
        return o1.id === o2.id
    }

    compare(o1, o2) {
        return o1 === o2
    }

}
