import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ChatRoutingModule} from './chat-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {ChatComponent} from './chat.component';
import {MessageComponent} from './message/message.component';
import {MessageEquipeComponent} from "./message-equipe/message-equipe.component";

@NgModule({
    declarations: [
        ChatComponent,
        MessageComponent,
        MessageEquipeComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ChatRoutingModule,
    ],
    exports: [
        MessageComponent,
    ],
    entryComponents: [
        MessageComponent,
        MessageEquipeComponent,
    ]
})
export class ChatModule {
}
